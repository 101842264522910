import * as Yup from 'yup';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import {currencyList} from '../constants/currencyList';
import {
  CARD_TYPE_ICONS,
  DEFAULT_LANGUAGE,
  TIME_UNITS,
  PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES,
  ADD_NEW_OPTION_VALUE,
  SPACE_REG_EXP,
  MONTH_MAX_DIGITS,
  EXPIRATION_DATE_LENGTH,
  CARD_NUMBER_MAX_LENGTH
} from '../constants/common';
import {
  ADDITIONAL_PAYMENTS_CONTAINER_MAX_HEIGHT,
  ADDITIONAL_PAYMENTS_SHOW_VALUE,
  SINGLE_PAYMENT_MAX_HEIGHT
} from '../constants/bonusPayments';

const {SET_OPTIONS, DELETE_OPTION} = PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES;
const {FIRST_DIGIT, SECOND_DIGIT, MONTH_OVERFLOW} = MONTH_MAX_DIGITS;

/**
 * Retrieve the localized value from the provided property descriptions based on the given language.
 * If the value for the specified language is not available, it falls back to the default language.
 * @param {Object} propertyDescriptions - Object containing property descriptions for different languages.
 * @param {string} language - The language code for which the value is requested.
 * @returns {string} - The localized value for the specified language, or the default language if not available.
 */
const getLocalizedValue = (propertyDescriptions, language) => propertyDescriptions[language] || propertyDescriptions[DEFAULT_LANGUAGE];

/**
 * Retrieve control property descriptions for the specified language from the provided field controls.
 * If the language is not specified, it falls back to the default language.
 * @param {Object[]} fieldControls - An array of field control objects.
 * @param {string} [language] - The language code for which the descriptions are requested. If not provided, the default language is used.
 * @returns {Object} - An object containing control property descriptions keyed by control property type name.
 */
const getControlPropertyDescriptions = (fieldControls, language) => fieldControls.reduce((controlProperties, control) => {
  const {
    controlPropertyTypeName,
    propertyDescriptions
  } = control;
  controlProperties[controlPropertyTypeName] = getLocalizedValue(
    propertyDescriptions,
    language || DEFAULT_LANGUAGE
  );
  return controlProperties;
}, {});

/**
 * removes spaces from string
 * @param {string} string
 * @returns {string}
 */
const removeSpaceFromString = (string) => string?.replace(SPACE_REG_EXP, '');

const getCurrentYearLastDigits = () => new Date().getFullYear() % 100;

/**
 * Generate validation schemas for dynamic controls based on the provided control properties and formatting.
 * @param {Object[]} dynamicControls - An array of dynamic control objects.
 * @param {Object} formattedControls - An object containing formatted control properties.
 * @param {Object} validationSchemas - An object to store generated validation schemas.
 * @param {string} lang - The language code used for localization.
 * @returns {Object} - The updated validation schemas object containing validation rules for each dynamic control.
 */
export const controlValidationScheme = (
  dynamicControls,
  formattedControls,
  validationSchemas,
  lang
) => {
  dynamicControls?.forEach((curr) => {
    const controlProperties = getControlPropertyDescriptions(
      curr.fieldControls,
      lang
    );

    const isRequired = formattedControls[curr.key]?.hidden?.value?.toString() === 'true'
      ? false
      : formattedControls[curr.key]?.required?.value === 'true';

    const isRegexpRequired = formattedControls[curr.key]?.hidden?.value?.toString() === 'true'
      ? false
      : formattedControls[curr.key]?.regexp?.value;
    let schema = Yup.string();

    if (isRequired) {
      schema = schema.required(controlProperties.required);
    }
    if (isRegexpRequired) {
      schema = schema.matches(
        new RegExp(formattedControls[curr.key]?.regexp?.value),
        controlProperties.regexp
      );
    }
    validationSchemas[curr.key] = schema;
  });

  return validationSchemas;
};

/**
 * Format dynamic controls into a structured object containing control properties.
 * @param {Object[]} dynamicControls - An array of dynamic control objects.
 * @returns {Object} - An object containing formatted control properties grouped by control keys.
 */
const formattedControls = (dynamicControls) => dynamicControls?.reduce((acc, fieldControlItem) => {
  acc[fieldControlItem?.key] = fieldControlItem?.fieldControls?.reduce(
    (obj, fieldControl) => {
      obj[fieldControl?.controlPropertyTypeName] = fieldControl;
      return obj;
    },
    {}
  );
  return acc;
}, {});

/**
 * Generate initial values for dynamic controls.
 * @param {Object[]} dynamicControls - An array of dynamic control objects.
 * @returns {Object} - An object containing initial values keyed by control keys.
 */
const initialValues = (dynamicControls) => dynamicControls?.reduce((acc, fieldControlItem) => {
  if (fieldControlItem?.key !== null) {
    acc[fieldControlItem?.key] = '';
  }
  return acc;
}, {});

/**
 * Computes the SHA256 hash from a given input string.
 *
 * @param {string} input - The input string to compute the hash from.
 * @returns {string} The SHA256 hash in hexadecimal format.
 */
export const computeSha256HashFromString = (input) => {
  const hash = sha256(input);
  return hash.toString(CryptoJS.enc.Hex);
};


/**
 * Returns either the currency symbol or the code based on the setting.
 * @param {string} currency - The currency code.
 * @param {boolean} isCurrencySymbol - The isCurrencySymbol to determine whether to return the symbol or the code.
 * @returns {string} The currency symbol or code based on the setting.
 */

const getCurrencySymbolOrCode = (currency, isCurrencySymbol) => {
  const upperCaseCurrency = currency?.toUpperCase();
  return isCurrencySymbol && currencyList[upperCaseCurrency]
    ? currencyList[upperCaseCurrency].symbol
    : upperCaseCurrency;
};

function formatDate(dateString) {
  const options = {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false
  };
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.getTime() - userTimezoneOffset);
  return new Intl.DateTimeFormat('de-DE', options).format(adjustedDate).replace(',', '');
}

export const sortArrayByKeyValue = ({array, key, value}) => {
  if (array?.length) {
    if (value === ADD_NEW_OPTION_VALUE) {
      return array;
    }
    const customSort = (a, b) => {
      if (a[key] === value) {
        return -1;
      }
      if (b[key] === value) {
        return 1;
      }
      return 0;
    };

    return array.sort(customSort);
  }

  return null;
};

export const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return window.btoa(binary);
};

export const getControlPropertyValues = (fieldControls, language) => fieldControls.reduce((controlProperties, {
  controlPropertyTypeName,
  propertyDescriptions,
  value
}) => {
  const localizedDescription = getLocalizedValue(
    propertyDescriptions,
    language || DEFAULT_LANGUAGE
  );
  controlProperties[controlPropertyTypeName] = localizedDescription ?? value;
  return controlProperties;
}, {});

export const generatePaymentDataForBonusList = (paymentsList, simplifiedData) => paymentsList?.map((payment) => {
  const {
    disableMethod, unavailable, hasRedirect, hasVerifiedAccount
  } = simplifiedData?.find((item) => item?.paymentSystemId === payment?.id) ?? {};
  return {
    ...payment, disableMethod, unavailable, hasRedirect, hasVerifiedAccount
  };
});

export const findMinMaxAmountsForBonus = (conditions) => {
  let minAmount = Number.MAX_SAFE_INTEGER;
  let maxAmount = Number.MIN_SAFE_INTEGER;

  conditions?.forEach(({minDeposit, maxDeposit}) => {
    if (minDeposit < minAmount) {
      minAmount = minDeposit;
    }
    if (maxDeposit > maxAmount) {
      maxAmount = maxDeposit;
    }
  });

  return [minAmount, maxAmount];
};

export const calculateBonusExpiration = (expirationPeriod) => {
  const days = Math.floor(expirationPeriod / 24);
  const hours = Math.floor(expirationPeriod % 24);

  return `${days}d:${hours}h`;
};

export const validateNumericInput = (input) => !Number.isNaN(Number(removeSpaceFromString(input.trim())));

/**
 * Validates card number input based on Luhn algorithm.
 * @param {string} cardNumber - The card number.
 * @returns {boolean} is inserted card number valid or not.
 */
export const validateCardNumberInput = (cardNumber) => {
  const cardNumberNoSpace = removeSpaceFromString(cardNumber);
  if (cardNumberNoSpace?.length > 13 && cardNumberNoSpace.length < 21 && cardNumberNoSpace[0] !== '0') {
    const numbersCountReminder = cardNumberNoSpace.length % 2;
    let acc = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = cardNumberNoSpace.length; i < len; ++i) {
      let num = Number(cardNumberNoSpace[i]);
      if (i % 2 === numbersCountReminder) {
        num *= 2;
        if (num > 9) num -= 9;
      }
      acc += num;
    }

    return acc % 10 === 0;
  }

  return false;
};

/**
 * Validates card number input value from form values.
 * @param {object} payloadValues - The card number.
 * @returns {boolean}
 */
export const validateCardNumberFromPayloadValues = (payloadValues) => {
  const cardNumberKey = Object.keys(payloadValues)?.find((key) => key.toLowerCase().includes('cardnumber'));
  if (cardNumberKey) {
    return validateCardNumberInput(payloadValues[cardNumberKey]);
  }

  return true;
};

const renderProcessingTime = (processingTimeDetail, t) => {
  const {processingTimeFrom, processingTimeTo, processingTimeUnit} = processingTimeDetail || {};

  if (processingTimeUnit === TIME_UNITS.INSTANT) {
    return t('instant'.toLowerCase());
  }

  if (processingTimeFrom === 0 && processingTimeTo === 0) {
    return null;
  }

  let unit;
  switch (processingTimeUnit) {
    case TIME_UNITS.HOURLY:
      unit = t('hourly'.toLowerCase());
      break;
    case TIME_UNITS.MINUTE:
      unit = t('minute'.toLowerCase());
      break;
    default:
      unit = null;
      break;
  }

  return `${processingTimeFrom} - ${processingTimeTo} ${unit}`;
};

export const decodeHTML = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

/**
 * Checks expiration date string symbols to be numeric during onchange event
 * @param {string} expirationDate
 * @returns {boolean}
 */
// eslint-disable-next-line no-unsafe-optional-chaining
export const validateExpirationDateSymbols = (expirationDate) => {
  const firstDigit = Number(expirationDate[0]);
  const secondDigit = Number(expirationDate[1]);
  const preLastDigit = Number(expirationDate[3]);
  const lastDigit = Number(expirationDate[4]);

  const currentYear = String(getCurrentYearLastDigits()).split('').map(Number);


  if (
    firstDigit > FIRST_DIGIT
    || (firstDigit === FIRST_DIGIT && secondDigit > SECOND_DIGIT)
    || (firstDigit === 0 && secondDigit === 0)
    || (preLastDigit < currentYear[0])
    || (preLastDigit === currentYear[0] && lastDigit < currentYear[1])
  ) {
    return true;
  }

  return Number.isNaN(expirationDate?.split('/').join('') - 0);
};

/**
 * Checks expiration date string format
 * @param {string} expirationDate
 * @returns {boolean}
 */
export const isValidExpirationDate = (expirationDate) => {
  if (expirationDate?.length !== EXPIRATION_DATE_LENGTH) return false;
  const [month, year] = expirationDate.split('/').map(Number);

  const currentYear = getCurrentYearLastDigits();

  return month < MONTH_OVERFLOW && year >= currentYear;
};

/**
 * Formats card number input value according to card number spacing logic
 * @param {string} cardNumber
 * @returns {string}
 */
export const formatCardNumberString = (cardNumber) => {
  const noSpaceString = removeSpaceFromString(cardNumber);
  const noSpaceLength = noSpaceString?.length;

  if (!noSpaceLength) return '';
  if (noSpaceLength > CARD_NUMBER_MAX_LENGTH) return cardNumber.slice(0, -1);

  const formatString = (start, middle, end) => `${start} ${middle} ${end}`.replace(/\s+/g, ' ').trim();

  if (noSpaceLength < 11 || noSpaceLength % 4 === 0 || noSpaceLength > 17) {
    return noSpaceString.replace(/(.{4})/g, '$1 ').trim();
  } if (noSpaceLength > 11 && noSpaceLength < 15) {
    const start = noSpaceString.slice(0, 4);
    const middle = noSpaceString.slice(4, -4);
    const end = noSpaceString.slice(-4);
    return formatString(start, middle, end);
  } if (noSpaceLength === 15) {
    const start = noSpaceString.slice(0, 4);
    const middle = noSpaceString.slice(4, -5);
    const end = noSpaceString.slice(-5);
    return formatString(start, middle, end);
  }

  return cardNumber;
};

/**
 * Detects card type by card number
 * @param {string} cardNumber
 * @returns {React.node || null}
 */
export const getCardTypeIcon = (cardNumber) => {
  const cleaned = removeSpaceFromString(cardNumber);
  const {
    0: DefaultCardIcon,
    1: VisaIcon,
    2: MasterIcon,
    3: AmexIcon,
    4: DinersIcon,
    5: DiscoverIcon,
    6: JCBIcon,
    8: MaestroIcon
  } = CARD_TYPE_ICONS;

  if (cleaned) {
    if (/^4\d{12}(\d{3})?$/.test(cleaned)) return VisaIcon;
    if (/^5[1-5]\d{14}$/.test(cleaned) || /^2(2[2-9]|[3-6]\d|7[01])\d{12}$/.test(cleaned)) return MasterIcon;
    if (/^3[47]\d{13}$/.test(cleaned)) return AmexIcon;
    if (/^6(?:011|5\d{2}|22[1-9]\d|2[3-9]\d|[4-6]\d{2}|7[0-2]\d|7[3-9]\d)\d{12}$/.test(cleaned)) return DiscoverIcon;
    if (/^3(?:0[0-5]|[68]\d)\d{11}$/.test(cleaned)) return DinersIcon;
    if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cleaned)) return JCBIcon;
    if (/^5[0678]\d{10,17}$/.test(cleaned) || /^6[389]\d{10,17}$/.test(cleaned)) return MaestroIcon;
    if (/^(5018|5020|5038|6304|6759|676[1-3])\d{8,15}$/.test(cleaned)) return MaestroIcon;

    return DefaultCardIcon;
  }

  return null;
};

export {
  getLocalizedValue,
  getControlPropertyDescriptions,
  formattedControls,
  initialValues,
  getCurrencySymbolOrCode,
  formatDate,
  renderProcessingTime
};

/**
 * Adjusts the frequency of function calls
 * @param {function} func
 * @param {number} delay
 * @param {object} timeoutRef
 */
export const timeoutDebounce = (func, timeoutRef, delay) => (...args) => {
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }
  timeoutRef.current = setTimeout(() => {
    func(...args);
  }, delay);
};

/**
 * Detects bonus additional payments container open direction based on content size
 * @param {number} bottom
 * @param {number} additionalPaymentsCount
 * @return string
 */
export const detectBonusAdditionalPaymentsContainerOpenDirection = (bottom, additionalPaymentsCount) => {
  if (bottom && additionalPaymentsCount) {
    const remainingSpaceFromBottom = window.innerHeight - bottom;
    const contentHeight = additionalPaymentsCount * SINGLE_PAYMENT_MAX_HEIGHT + 15;
    const contentActualHeight = contentHeight > ADDITIONAL_PAYMENTS_CONTAINER_MAX_HEIGHT ? ADDITIONAL_PAYMENTS_CONTAINER_MAX_HEIGHT : contentHeight;

    return ADDITIONAL_PAYMENTS_SHOW_VALUE[remainingSpaceFromBottom < contentActualHeight ? 'BOTTOM' : 'TOP'];
  }

  return ADDITIONAL_PAYMENTS_SHOW_VALUE.CLOSE;
};

export const paymentRightSideOptionsCallbacks = (function() {
  const callbacks = {
    [SET_OPTIONS]: null,
    [DELETE_OPTION]: null
  };

  const addCallback = (name, callback) => {
    if (name && typeof callback === 'function') {
      callbacks[name] = callback;
    }
  };

  const invokeCallback = (name, ...args) => {
    if (name && typeof callbacks[name] === 'function') {
      callbacks[name].call(null, ...args);
    }
  };

  const resetCallbacks = () => {
    callbacks[SET_OPTIONS] = null;
    callbacks[DELETE_OPTION] = null;
  };

  return {addCallback, invokeCallback, resetCallbacks};
})();

/**
 * Check if select options contains at least one option except add new
 *
 * @param {array} options
 * @returns {boolean}
 */
export const checkAreSelectOptionsLeft = (options) => (!options?.length || options.filter((item) => item.value !== ADD_NEW_OPTION_VALUE).length === 0);

