import styled, {css} from 'styled-components';
import {StyledPaymentName} from '../../components/paymentsForm/templateOne/web/formWeb.styled';
import {flexColumnContainer, flexContainer, fontStyle} from '../../helpers/styled';

const StyledSectionDivider = styled.section`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  
  ul.withoutPaddingLeft {
    padding-left: 0;
  }
`;

const StyledItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-gap: 4px;

  ${(props) => props.isExpanded && css`
    margin-bottom: 8px;
  `}

  ${(props) => props.isMobile && css`
    margin-bottom: 4px;
  `}
`;

const StyledItemsTitle = styled(StyledPaymentName)`
  font-size: ${({theme}) => theme.typographyFontSizeMiddle};

  ${(props) => props.isMobile && css`
    color: ${({theme}) => theme.mainButtonBackgroundColor};
  `}
`;

const StyledListItemsWrapper = styled.ul`
  ${flexColumnContainer()};
  grid-gap: ${(props) => (props.isMobile ? '4px' : '8px')};
`;

const StyledListItem = styled.li`
  ${flexContainer()};
  justify-content: space-between;
  height: 24px;
  line-height: 24px;
`;

const StyledUploadedList = styled.li`
  ${flexColumnContainer()};
  grid-gap: 16px;

  ul.withoutPaddingLeft {
    padding-left: 0;
  }
  
  h3 {
    justify-content: flex-start;
  }
`;

const StyledHistoryUploadedWrapper = styled.ul`
  ${flexColumnContainer()};
  grid-gap: 24px;
`;

const StyledListWrapper = styled.div`
  ${flexColumnContainer()};
  grid-gap: 8px;
`;
const StyledListTitle = styled.h3`
  ${fontStyle('semiBold')};
  color: ${(props) => props.theme.textContentColor};
`;

const StyledListContent = styled.ul`
  ${flexColumnContainer()};
  grid-gap: 8px;
  margin-left: 26px;
  ${fontStyle('regular')};
  color: ${(props) => props.theme.textContentColor};
  list-style-type: disc;
`;

export default StyledSectionDivider;
export {
  StyledItemsTitle,
  StyledListItemsWrapper,
  StyledListItem,
  StyledItemTitleWrapper,
  StyledUploadedList,
  StyledHistoryUploadedWrapper,
  StyledListWrapper,
  StyledListTitle,
  StyledListContent
};

