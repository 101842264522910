import React, {
  useCallback, useContext, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import RenderPaymentForm from '../../common/renderPaymentForm';
import EnhancedFormik from '../../common/enhancedFormik';
import {formWebHOC} from '../../../../HOC/formWebHOC';
import {renderProcessingTime} from '../../../../helpers/common';
import {templateTwoFormRightSideOptionsHOC} from '../../../../HOC/templateTwoFormRightSideOptionsHOC';
import FormDetailsTemplateTwo from '../../../paymentsDetails/templateTwo/paymentsDetails';
import PaymentCardWeb from './paymentCardWeb';
import Tabs from '../../../tabs/tabs';
import Tab from '../../../tabs/tab';
import GenerateCode from '../../../generateCode/generateCode';
import HistoryWeb from '../../../paymentsHistory/templateTwo/web/historyWeb';
import GeneratedActiveCodesList from '../../../generatedActiveCodesList/generatedActiveCodesList';
import PaymentBonusesListWeb from '../../../paymentBonusesList/paymentBonusesListWeb';
import PaymentSelectDeletableOptions from '../../../paymentSelectDeletableOptions/paymentSelectDeletableOptions';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {PaymentBonusesContext} from '../../../../contexts/paymentBonusesContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import VerificationHistoryContainer
  from '../../../verificationHistoryContainer/templateTwo/VerificationHistoryContainer';
import {
  AccountIcon, BonusIcon, CardIcon, DetailsIcon, HistoryIcon
} from '../../../../assets/icons';

/* Styled Components */
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import {StyledButtonWithoutBg} from '../../../../styledComponents/button/button.styled';
import StyledPaymentFormContainer, {
  StyledGenerateCodeWrapperTwoTemplate,
  StyledPaymentFormDefaultWrapper,
  StyledPaymentFormInfoWrapper,
  StyledPaymentItemLabelTwoTemplate,
  StyledSectionHeaderWrapper
} from './formWeb.styled';
import {
  StyledCheckbox,
  StyledCheckboxInputLabel,
  StyledCheckboxInputWrapper,
  StyledCheckboxWrapper
} from '../../../../styledComponents/input/input.styled';
import {StyledBackToText} from '../../../paymentsDetails/templateTwo/paymentsDetails.styled';
import {StyledItemValueTemplateTwo} from '../../../paymentBalances/templateTwo/paymentBalances.styled';

function FormWebTemplateTwo({
  baseUrl,
  isDefault,
  deviceType,
  alertContext,
  translations,
  paymentLimits,
  paymentBalances,
  activeCodesList,
  applePaySession,
  isCurrencySymbol,
  paymentDescription,
  isDefaultAvailable,
  paymentControlsData,
  showActiveCodesList,
  defaultToggleHandler,
  showGeneratedCodeInfo,
  payPalTransactionData,
  paymentRightSideOptions,
  googlePayTransactionData,
  paymentGeneratedCodeInfo,
  closeActiveCodeListHandler,
  goBackToCodeGeneratorHandler,
  paymentData,
  paymentSources,
  templateType,
  setUpdateFee
}) {
  const {currency} = useContext(CashierDetailsContext);
  const {paymentBonuses} = useContext(PaymentBonusesContext);
  const {backToListHandler} = useContext(PaymentFormCallbacksContext);
  const {verificationHistory} = useContext(AccountVerificationHistoryContext);

  const {t} = useTranslation();

  const {hasHistory, paymentSystemName} = paymentControlsData || {};

  const [dataName, setDataName] = useState('generatedCode');
  const [showPaymentBonusesForWeb, setShowPaymentBonusesForWeb] = useState(false);

  const openPaymentFormBonusesForWeb = useCallback(() => {
    setShowPaymentBonusesForWeb(true);
  }, []);
  const onCloseAllBonusesForWebHandler = useCallback(() => {
    setShowPaymentBonusesForWeb(false);
  }, []);

  const tabData = useMemo(() => {
    const defaultTabs = [
      {
        id: 'details',
        title: t(('details').toLowerCase()), // You can use a translation function here
        icon: <DetailsIcon />, // Icon component from react-icons
        content: <FormDetailsTemplateTwo
          paymentLimits={paymentLimits}
          paymentBalances={paymentBalances}
          paymentDescription={paymentDescription}
          paymentSystemName={paymentSystemName}
        />
      }
    ];

    if (verificationHistory?.length > 0) {
      defaultTabs.push({
        id: 'verificationAccounts',
        title: 'Accounts',
        icon: <AccountIcon />,
        content: <VerificationHistoryContainer forTempTwo historyData={verificationHistory} />
      });
    }

    if (paymentBonuses?.length > 0) {
      defaultTabs.push({
        id: 'bonuses',
        title: 'Bonuses',
        icon: <BonusIcon />,
        content: <PaymentBonusesListWeb forTempTwo onCloseListHandler={onCloseAllBonusesForWebHandler} />
      });
    }

    if (paymentRightSideOptions) {
      const {id, title, optionsList} = paymentRightSideOptions;
      defaultTabs.push({
        id,
        title,
        icon: <CardIcon />, // Icon component from react-icons,
        content: optionsList?.length ? <PaymentSelectDeletableOptions optionsList={optionsList} /> : null
      });
    }

    if (hasHistory) {
      defaultTabs.push({
        id: 'history',
        title: 'History',
        icon: <HistoryIcon />,
        content: <HistoryWeb alertContext={alertContext} />
      });
    }

    return defaultTabs;
  }, [
    hasHistory,
    paymentLimits,
    paymentBonuses,
    paymentBalances,
    paymentSystemName,
    paymentDescription,
    verificationHistory,
    paymentRightSideOptions,
    onCloseAllBonusesForWebHandler,
    paymentControlsData
  ]);

  return (
    <StyledPaymentFormContainer>
      <section style={{overflowX: 'hidden'}}>
        <StyledSectionHeaderWrapper onClick={backToListHandler}>
          <StyledButtonWithoutBg
            key="backToList"
            type="button"
          >
            <StyledIconSmall icon="arrowLeft" fontSize="11px" />
            <StyledBackToText>
              {t(('backToList')?.toLowerCase())}
            </StyledBackToText>
          </StyledButtonWithoutBg>
        </StyledSectionHeaderWrapper>
        <StyledPaymentFormInfoWrapper>
          <PaymentCardWeb
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            paymentData={paymentData}
            translations={translations}
          />
          {/* eslint-disable-next-line no-nested-ternary */}
          {paymentSources?.length ? (
            <StyledGenerateCodeWrapperTwoTemplate>
              <GenerateCode
                setDataName={setDataName}
                dataName={dataName}
                codeDetailsData={paymentSources}
                translations={translations}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            </StyledGenerateCodeWrapperTwoTemplate>
            // eslint-disable-next-line no-nested-ternary
          ) : showGeneratedCodeInfo ? (
            <GeneratedActiveCodesList
              translations={translations}
              alertContext={alertContext}
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
              codesList={[{data: paymentGeneratedCodeInfo}]}
              onGoBackHandler={goBackToCodeGeneratorHandler}
            />
          ) : showActiveCodesList && activeCodesList?.length > 0 ? (
            <GeneratedActiveCodesList
              codesList={activeCodesList}
              onGoBackHandler={closeActiveCodeListHandler}
              alertContext={alertContext}
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
            />
          ) : (
            <>
              <EnhancedFormik
                baseUrl={baseUrl}
                applePaySession={applePaySession}
                alertContext={alertContext}
                payPalTransactionData={payPalTransactionData}
                googlePayTransactionData={googlePayTransactionData}
                formComponent={RenderPaymentForm}
                deviceType={deviceType}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                paymentControlsData={paymentControlsData}
                templateType={templateType}
                openPaymentFormBonusesForWeb={openPaymentFormBonusesForWeb}
                betTaxAmount={paymentBalances?.betTaxAmount === 0 ? '0' : paymentBalances?.betTaxAmount}
                setUpdateFee={setUpdateFee}
              />
              <StyledPaymentFormDefaultWrapper>
                {isDefaultAvailable && (
                  <StyledCheckboxInputWrapper>
                    <StyledCheckboxWrapper>
                      <StyledCheckbox
                        id="label"
                        className="text-input"
                        type="checkbox"
                        onChange={defaultToggleHandler}
                        checked={isDefault}
                      />
                    </StyledCheckboxWrapper>
                    <StyledCheckboxInputLabel htmlFor="label">
                      {t(('setAsDefault')?.toLowerCase())}
                    </StyledCheckboxInputLabel>
                  </StyledCheckboxInputWrapper>
                )}
                {paymentControlsData?.processingTimeDetail && renderProcessingTime(paymentControlsData?.processingTimeDetail, t) !== null ? (
                  <div>
                    <StyledPaymentItemLabelTwoTemplate>
                      {t(('transactionTime')?.toLowerCase())}
                      :
                      <StyledItemValueTemplateTwo>
                        {renderProcessingTime(paymentControlsData?.processingTimeDetail, t)}
                      </StyledItemValueTemplateTwo>
                    </StyledPaymentItemLabelTwoTemplate>
                  </div>
                ) : null}
              </StyledPaymentFormDefaultWrapper>
            </>
          )}
        </StyledPaymentFormInfoWrapper>
      </section>
      <Tabs childIndexToShow={showPaymentBonusesForWeb ? 1 : null}>
        {tabData.map((tab) => (
          <Tab key={tab.id} id={tab.id} title={tab.title} icon={tab.icon}>
            {tab.content}
          </Tab>
        ))}
      </Tabs>
    </StyledPaymentFormContainer>
  );
}

FormWebTemplateTwo.defaultProps = {
  paymentLimits: null,
  activeCodesList: [],
  paymentBalances: null,
  paymentDescription: null,
  isDefaultAvailable: false,
  showActiveCodesList: false,
  payPalTransactionData: null,
  paymentRightSideOptions: null,
  googlePayTransactionData: null,
  paymentGeneratedCodeInfo: null,
  paymentData: null,
  paymentSources: null,
  templateType: 2
};

FormWebTemplateTwo.propTypes = {
  paymentLimits: PropTypes.object,
  activeCodesList: PropTypes.array,
  paymentBalances: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  paymentDescription: PropTypes.array,
  showActiveCodesList: PropTypes.bool,
  payPalTransactionData: PropTypes.object,
  paymentRightSideOptions: PropTypes.object,
  paymentGeneratedCodeInfo: PropTypes.array,
  googlePayTransactionData: PropTypes.object,
  baseUrl: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  deviceType: PropTypes.number.isRequired,
  translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  applePaySession: PropTypes.bool.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  defaultToggleHandler: PropTypes.func.isRequired,
  paymentControlsData: PropTypes.object.isRequired,
  showGeneratedCodeInfo: PropTypes.bool.isRequired,
  closeActiveCodeListHandler: PropTypes.func.isRequired,
  goBackToCodeGeneratorHandler: PropTypes.func.isRequired,
  paymentData: PropTypes.array,
  // paymentSystemId: PropTypes.number,
  paymentSources: PropTypes.array,
  templateType: PropTypes.number,
  setUpdateFee: PropTypes.func.isRequired
};
export default formWebHOC(templateTwoFormRightSideOptionsHOC(FormWebTemplateTwo));
