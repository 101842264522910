import styled from 'styled-components';
import {
  flexContainer,
  generateStatusMessageFieldColorTemplateTwo
} from '../../../../helpers/styled';
import {StyledPaymentItemLabel} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledStatusMessageWrapper} from '../../../../styledComponents/input/input.styled';
import {StyledLabelInfo} from '../../../../styledComponents/label/label.styled';

const StyledPaymentFormContainer = styled.main`
  width: 100%;
  height: ${({theme}) => (theme.adminWrapper ? '100%' : 'auto')};
  display: grid;
  grid-template-columns: 1fr 380px;
  background-color: ${(props) => props.theme.surface.surface};
`;

const StyledSectionHeaderWrapper = styled.div`
  ${flexContainer};
  min-height: 44px;
  padding: 0 32px;
  border-bottom: 1px solid ${({theme}) => theme.surface.surfaceTertiary};
  cursor: pointer;
  
  button {
    width: 100%;
  }
  
  span {
    flex: 1;
    margin-left: -24px;
  }
`;

const StyledPaymentFormInfoWrapper = styled.div`
  padding: 40px 90px 24px;
`;

const StyledPaymentFormDefaultWrapper = styled.div`
  margin-top: 24px;
  ${flexContainer({justifyContent: 'space-between'})};
`;

const StyledPaymentItemLabelTwoTemplate = styled(StyledPaymentItemLabel)`
  gap: 4px;
  color: ${({theme}) => theme.textTitleColor};
`;

const StyledGenerateCodeWrapperTwoTemplate = styled.section`
  width: 100%;
  margin: 0 auto;
`;

const StyledStatusMessageWrapperTemplateTwo = styled(StyledStatusMessageWrapper)`
  border: 1px dashed ${({state}) => generateStatusMessageFieldColorTemplateTwo(state)};
  background-color: ${({theme}) => theme.surface.surfaceSecondary};

  svg path {
    fill: ${({state}) => generateStatusMessageFieldColorTemplateTwo(state)};
  }
`;

const StyledLabelInfoWrapperTemplateTwo = styled(StyledStatusMessageWrapper)`
  display: flex;
  justify-content: flex-start;
  border: 1px dashed ${({theme}) => theme.alertLinkColor};
  background-color: ${({theme}) => theme.surface.surfaceSecondary};
`;

const StyledLabelInfoTemplateTwo = styled(StyledLabelInfo)`
  text-align: left;
  margin: 0;
`;

export default StyledPaymentFormContainer;
export {
  StyledSectionHeaderWrapper,
  StyledPaymentFormInfoWrapper,
  StyledPaymentFormDefaultWrapper,
  StyledPaymentItemLabelTwoTemplate,
  StyledGenerateCodeWrapperTwoTemplate,
  StyledStatusMessageWrapperTemplateTwo,
  StyledLabelInfoWrapperTemplateTwo,
  StyledLabelInfoTemplateTwo
};
