import styled, {css} from 'styled-components';
import {CODE_TYPE} from '../../constants/common';
import {flexContainer, fontStyle, generateBgImageUrl} from '../../helpers/styled';
import {StyledHistoryDetailsDataWrapper} from '../paymentsHistory/templateOne/web/historyWeb.styled';

const codeTypeStyles = {
  [CODE_TYPE.BAR_CODE]: {
    bgImageParams: [140, 61, 37],
    dimensions: {width: '260px', height: '100px'}
  },
  [CODE_TYPE.QR_CODE]: {
    bgImageParams: [100, 60, 54],
    dimensions: {width: '140px', height: '140px'}
  }
};

const StyledCodeBorderWrapped = styled.div`
  padding: 12px;
  border-radius: 8px;
  margin-bottom: ${(props) => (props.withoutMarginBottom ? '0' : '16px')};
  overflow: hidden;
  
  ${(props) => props.codeType === CODE_TYPE.BAR_CODE && css`
    background-image: url("${generateBgImageUrl(props, 140, 61, 37)}")
  `}

  ${(props) => props.codeType === CODE_TYPE.QR_CODE && css`
    background-image: url("${generateBgImageUrl(props, 100, 60, 54)}")
  `}
`;

const StyledCodeLabel = styled.span`
  height: 24px;
  line-height: 24px;
  ${fontStyle('regular')};
  color: ${({theme}) => theme.cardTextValueColor};
`;

const StyledCodeWrapper = styled.div`
  padding: ${(props) => (props.withoutPadding ? '0' : '12px')};
  border-radius: 4px;
  margin: 0 auto;
  background-color: ${({theme}) => theme.generalColorNeutralMinimum};

  ${({codeType}) => codeTypeStyles[codeType] && css`
    width: ${codeTypeStyles[codeType].dimensions.width};
    height: ${codeTypeStyles[codeType].dimensions.height};
  `}

  img, svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledGenerateCodeList = styled.ul`
  border-radius: 8px;
  background-color: ${({theme}) => theme.surface.surfaceTertiary};
  overflow: hidden;
  padding-left: 0 !important;
`;

const StyledMainCodeWrapper = styled.div`
  width: 100%;
  ${flexContainer({
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  })};
  padding: 16px 0 8px 0;
  background-color: ${({theme}) => theme.surface.surfaceSecondary};
`;

const StyledGenerateCodeListItemWrapper = styled(StyledHistoryDetailsDataWrapper)`
  ${flexContainer({
    justifyContent: 'space-between',
    alignItems: 'center'
  })};
  min-height: 40px;
  padding: 0 8px;
  border-bottom: 1px solid ${({theme}) => theme.border.border};
  
  &:last-child, &:first-child {
    border: 0;
  }
  
  ${({isGeneratedCode}) => isGeneratedCode && css`
    padding: 0;
  `}
`;

export {
  StyledCodeBorderWrapped,
  StyledCodeLabel,
  StyledCodeWrapper,
  StyledMainCodeWrapper,
  StyledGenerateCodeList,
  StyledGenerateCodeListItemWrapper
};
