import styled, {css} from 'styled-components';
import {flexContainer, fontStyle} from '../../helpers/styled';
import {StyledPaymentCard} from '../bonusPaymentItem/bonusPaymentItem.styled';

const StyledTabsContainer = styled.section`
  height: 100%;
  ${flexContainer({flexDirection: 'column'})};
  overflow: hidden;
`;

const StyledTabsHeader = styled.header`
  height: 44px;
  width: 100%;
  ${flexContainer({justifyContent: 'flex-start'})};
  background-color: ${({theme}) => theme.surface.surfaceTertiary};
  border-bottom: 1px solid ${({theme}) => theme.surface.surfaceQuaternary};
  cursor: pointer;
`;

const StyledTabButton = styled.button`
  position: relative;
  ${flexContainer({gap: '8px'})};
  ${fontStyle('semiBold')}
  background-color: transparent;
  color: ${({theme}) => theme.text.textSecondary};
  border: none;
  height: 100%;
  padding: 10px 16px;

  svg path {
    fill: ${({theme}) => theme.surface.surfaceIcon};
  }
  
  ${({activeTab}) => activeTab && css`
    color: ${({theme}) => theme.brandColor};

    svg path {
      fill: ${({theme}) => theme.brandColor};
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 80%;
      height: 3px;
      background-color:${({theme}) => theme.brandColor};
      transform: translate(-50%);
      transform-origin: left;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transition: transform 0.3s ease-in-out;
    }
  `}
`;

const StyledTabsContent = styled.div`
  flex: 1;
  width: 100%;
  padding: 16px 12px;
  background-color: ${({theme}) => (theme.surface.surfaceSecondary)};
  overflow: auto;

  article ul {
    padding-left: 24px;

    li {
      list-style-type: disc;
    }
  }
`;

const StyledCardTab = styled.div`
  ${flexContainer({
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '8px'
  })};
`;

const StyledFlipCardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 78px;
  perspective: 800px;
`;

const StyledFlipCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: ${(props) => (props.showConfirmation ? 'rotateY(180deg)' : 'rotateY(0deg)')};
  transform-style: preserve-3d;
  transition: 400ms;
`;

const StyledFlipAlertWrapper = styled(StyledPaymentCard)`
  ${flexContainer({
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '16px'
  })};
  padding: 8px 12px 0;
  background-color:  ${({theme}) => theme.surface.surface};
  transform: rotateY(180deg);
`;

const StyledFlipAlertMessage = styled.h2`
  ${fontStyle('semiBold')};
  line-height: 22px;
  text-transform: capitalize;
  color: ${({theme}) => theme.cardTextValueColor};
`;

const StyledFlipAlertActions = styled.div`
  position: relative;
  width: 100%;
  ${flexContainer({justifyContent: 'flex-end'})};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 24px);
    height: 1px; 
    margin-left: -12px;
    background-color: ${({theme}) => `${theme.textContentColor}30`};
  }
`;

const StyledTextWithoutBg = styled.button`
  ${flexContainer()};
  padding: 6px 8px;
  border: 0;
  ${fontStyle('regular')};
  line-height: 20px;
  text-transform: capitalize;
  background-color: unset;
  color: ${({cancel, theme}) => (cancel ? theme.generalIconsColor : theme.mainButtonBackgroundColor)};
`;

const StyledCardNumber = styled.span`
  ${fontStyle('medium')};
  font-size: ${(props) => props.theme.typographyFontSizeMiddle};
  color: ${(props) => props.theme.textTitleColor};
`;

export default StyledTabsContainer;
export {
  StyledTabsHeader,
  StyledTabButton,
  StyledTabsContent,
  StyledFlipAlertWrapper,
  StyledFlipAlertMessage,
  StyledFlipAlertActions,
  StyledTextWithoutBg,
  StyledFlipCard,
  StyledFlipCardContainer,
  StyledCardTab,
  StyledCardNumber
};
