import React, {useCallback, useEffect, useMemo} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../helpers/common';
import {CODE_TYPE} from '../../constants/common';
import {CopyField, HyperLinkField} from '../paymentsForm/controlsType/commonControlsType';

/* Styled Components */
import {StyledItemValue, StyledItemLabel} from '../paymentsHistory/templateOne/web/historyWeb.styled';
import {
  StyledCodeBorderWrapped,
  StyledCodeLabel,
  StyledCodeWrapper,
  StyledGenerateCodeList,
  StyledGenerateCodeListItemWrapper,
  StyledMainCodeWrapper
} from './generateCode.styled';

function GenerateCode({
  codeDetailsData,
  translations,
  setDataName,
  alertContext,
  currency,
  isCurrencySymbol
}) {
  const {t} = useTranslation();
  useEffect(() => {
    const relevantCode = codeDetailsData.find(({dataName}) => [CODE_TYPE.BAR_CODE, CODE_TYPE.QR_CODE].includes(dataName));

    if (relevantCode) {
      setDataName(relevantCode.dataName);
    }
  }, [codeDetailsData, setDataName]);

  /**
   * A callback function to handle opening an alert with a success message.
   * @callback openAlertHandler
   * @returns {void} This function does not return anything.
   */
  const openAlertHandler = useCallback(() => {
    alertContext?.success(t(('copyMessage').toLowerCase()));
  }, [alertContext, t]);

  /**
   * Renders a component based on the provided data.
   * If dataName is 'BarCode' and autoGenerate is true, renders a Barcode component wrapped in StyledCodeWrapper.
   * If dataName is 'BarCode' and autoGenerate is false, renders an image with the source set to data.
   * If dataName is 'QRCode' and autoGenerate is true, renders a QRCode component wrapped in StyledCodeWrapper.
   * If dataName is 'QRCode' and autoGenerate is false, renders an image with the source set to data.
   * If dataName is neither 'BarCode' nor 'QRCode', renders a StyledLabelInfoForDynamicLabel with the provided data.
   *
   * @param {string} dataName - The type of data ('BarCode' or 'QRCode').
   * @param {string} copyDataName - The data translation key.
   * @param {boolean} autoGenerate - Indicates whether to generate the code automatically.
   * @param {string} data - The data to be rendered (value for Barcode/QRCode or image source).
   * @param {boolean} hasCopy - Indicates if data is copiable.
   * @returns {React.ReactNode} The rendered component.
   */
  const renderComponent = ({
    dataName, autoGenerate, data, hasCopy, hasLink
  }) => {
    if ([CODE_TYPE.BAR_CODE, CODE_TYPE.QR_CODE].includes(dataName)) {
      const CodeComponent = dataName === CODE_TYPE.BAR_CODE ? Barcode : QRCode;

      return (
        <StyledMainCodeWrapper>
          <StyledCodeLabel>{t(('scanToPay').toLowerCase())}</StyledCodeLabel>
          <StyledCodeBorderWrapped codeType={dataName}>
            <StyledCodeWrapper codeType={dataName}>
              {autoGenerate ? <CodeComponent value={data} /> : <img src={data} alt={dataName} />}
            </StyledCodeWrapper>
          </StyledCodeBorderWrapped>
          {data && hasLink ? <HyperLinkField href={data} /> : null}
        </StyledMainCodeWrapper>
      );
    }

    if (hasCopy) {
      return <CopyField copyValue={data} dataName={dataName} alertHandler={openAlertHandler} />;
    }

    return (
      <StyledItemValue descriptionValue={dataName === CODE_TYPE.DESCRIPTION} textCenter>
        {dataName === CODE_TYPE.AMOUNT ? Number(data)?.toFixed(2) : data}
        {dataName === CODE_TYPE.AMOUNT && ` ${getCurrencySymbolOrCode(currency, isCurrencySymbol)}`}
      </StyledItemValue>
    );
  };

  const orderedData = useMemo(() => codeDetailsData?.sort((a, b) => a.order - b.order) || [], [codeDetailsData]);

  return (
    <StyledGenerateCodeList>
      {orderedData.map(({
        dataName, copyDataName, autoGenerate, data, hasCopy, hasLink, hasTranslation, dataType
      }) => (
        <StyledGenerateCodeListItemWrapper
          as="li"
          key={dataName}
          isGeneratedCode={[CODE_TYPE.BAR_CODE, CODE_TYPE.QR_CODE].includes(dataName)}
        >
          {dataType !== 14 && (
            <>
              {![CODE_TYPE.BAR_CODE, CODE_TYPE.QR_CODE, CODE_TYPE.DESCRIPTION].includes(dataName) && !hasCopy && (
                <StyledItemLabel fullValue={hasTranslation}>
                  {hasTranslation ? t(dataName) : (translations[dataName?.toLowerCase()] ?? dataName)}
                </StyledItemLabel>
              )}
              {renderComponent({
                dataName, copyDataName, autoGenerate, data, hasCopy, hasLink
              })}
            </>
          )}
          {data && dataType === 14 && <HyperLinkField href={data} />}
        </StyledGenerateCodeListItemWrapper>
      ))}
    </StyledGenerateCodeList>
  );
}

GenerateCode.defaultProps = {
  codeDetailsData: [],
  translations: {}
};

GenerateCode.propTypes = {
  codeDetailsData: PropTypes.array,
  translations: PropTypes.object,
  setDataName: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired
};
export default GenerateCode;
