import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode, renderProcessingTime} from '../../../../helpers/common';
import {KebabMenuIcon} from '../../../../assets/icons';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';

/* Styled Components */
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import {StyledPaymentName} from '../web/formWeb.styled';
import {StyledButtonWithoutBg, StyledFlexContainer} from '../../../../styledComponents/button/button.styled';
import {StyledListItem, StyledListItemsWrapper} from '../../../../styledComponents/list/list.styled';
import {StyledItemValue, StyledItemLabel} from '../../../paymentsHistory/templateOne/web/historyWeb.styled';
import {StyledPaymentDetailWrapperMobile} from './paymentInfoMobile.styled';

function PaymentInfoMobile({
  currency,
  isCurrencySymbol,
  isPaymentInfoInner,
  openMenuHandler,
  isMobile,
  // paymentSystemName,
  processingTimeDetail
}) {
  const {t} = useTranslation();
  const paymentSystemDetail = useContext(SelectedPaymentDetailsContext);
  const {backToListHandler} = useContext(PaymentFormCallbacksContext);

  return (
    <>
      {isPaymentInfoInner || (
      <StyledPaymentDetailWrapperMobile>
        <StyledButtonWithoutBg
          type="button"
          isPositionLeft
          onClick={backToListHandler}
        >
          <StyledIconSmall icon="arrowLeft" fontSize="11px" />
        </StyledButtonWithoutBg>
        <StyledFlexContainer fullWidth>
          <StyledPaymentName>
            {paymentSystemDetail?.paymentSystemName}
          </StyledPaymentName>
        </StyledFlexContainer>
        <StyledButtonWithoutBg
          isPositionRight
          type="button"
          onClick={openMenuHandler}
        >
          <KebabMenuIcon />
        </StyledButtonWithoutBg>
      </StyledPaymentDetailWrapperMobile>
      )}
      <StyledListItemsWrapper
        isMobile={isMobile}
        className="withoutPaddingLeft"
        isPaymentInfoInner={isPaymentInfoInner}
      >
        <StyledListItem>
          <StyledItemLabel>
            {t(('min')?.toLowerCase())}
            /
            {t(('max')?.toLowerCase())}
            :
          </StyledItemLabel>
          <StyledItemValue>
            {paymentSystemDetail?.min}
            {' - '}
            {paymentSystemDetail?.max}
            {' '}
            {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
          </StyledItemValue>
        </StyledListItem>
        {paymentSystemDetail?.fee !== null ? (
          <StyledListItem>
            <StyledItemLabel>
              {t(('fee')?.toLowerCase())}
              :
            </StyledItemLabel>
            <StyledItemValue>
              {paymentSystemDetail?.fee}
              {' '}
              %
            </StyledItemValue>
          </StyledListItem>
        ) : null}
        {processingTimeDetail && renderProcessingTime(processingTimeDetail, t) !== null ? (
          <StyledListItem>
            <StyledItemLabel>
              {t(('transactionTime')?.toLowerCase())}
              :
            </StyledItemLabel>
            <StyledItemValue>
              {renderProcessingTime(processingTimeDetail, t)}
            </StyledItemValue>
          </StyledListItem>
        ) : null}
      </StyledListItemsWrapper>
    </>
  );
}

PaymentInfoMobile.defaultProps = {
  openMenuHandler: null,
  currency: null,
  isCurrencySymbol: null,
  isPaymentInfoInner: false,
  isMobile: false,
  // paymentSystemName: '',
  processingTimeDetail: null
};

PaymentInfoMobile.propTypes = {
  openMenuHandler: PropTypes.func,
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool,
  isPaymentInfoInner: PropTypes.bool,
  isMobile: PropTypes.bool,
  // paymentSystemName: PropTypes.string,
  processingTimeDetail: PropTypes.object
};

export default PaymentInfoMobile;
