import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// styled components
import {StyledPaymentName} from '../paymentsForm/templateOne/web/formWeb.styled';
import {StyledCardInfoDescription} from '../bonusBanner/bonusBanner.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {
  StyledPaymentCard,
  StyledPaymentCardInfo,
  StyledPaymentCardInfoContent,
  StyledPaymentCardPicture,
  StyledPaymentHintForBonusItem
} from './bonusPaymentItem.styled';

function BonusPaymentItem({
  id,
  name,
  shortDescription,
  image,
  disableMethod,
  unavailable,
  hasRedirect,
  hasVerifiedAccount,
  onPaymentClickHandler
}) {
  const {t} = useTranslation();

  return (
    <StyledPaymentCard
      isPointer
      onClick={(e) => onPaymentClickHandler(e, {
        disableMethod,
        paymentSystemId: id,
        unavailable,
        hasRedirect,
        hasVerifiedAccount
      })}
    >
      <StyledPaymentCardPicture>
        <img src={image} alt={name} />
      </StyledPaymentCardPicture>
      <StyledPaymentCardInfo>
        <StyledPaymentCardInfoContent>
          <StyledPaymentName>
            {name}
          </StyledPaymentName>
          {unavailable ? (
            <StyledPaymentHintForBonusItem
              unavailable={unavailable}
            >
              {t(('underMaintenance')?.toLowerCase())}
            </StyledPaymentHintForBonusItem>
          ) : (
            <StyledCardInfoDescription>
              {shortDescription}
            </StyledCardInfoDescription>
          )}
        </StyledPaymentCardInfoContent>
        <StyledIconSmall icon="arrowRight" fontSize="11px" />
      </StyledPaymentCardInfo>
    </StyledPaymentCard>
  );
}

BonusPaymentItem.propTypes = {
  disableMethod: PropTypes.bool,
  unavailable: PropTypes.bool,
  hasRedirect: PropTypes.bool,
  hasVerifiedAccount: PropTypes.bool,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  onPaymentClickHandler: PropTypes.func.isRequired
};

BonusPaymentItem.defaultProps = {
  disableMethod: false,
  unavailable: false,
  hasRedirect: false,
  hasVerifiedAccount: false
};

export default memo(BonusPaymentItem);
