import React, {memo, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import GenerateCode from '../generateCode/generateCode';
import {BarCodeIcon} from '../../assets/icons';
import {StyledFlexContainerDynamic} from '../paymentBonusesList/paymentBonusesListWeb.styled';
import {
  StyledIconButtonWithBg,
  StyledLinkButton
} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {
  StyledBarCodeMessage,
  StyledGeneratedCodesField,
  StyledIndicatorWrapper
} from './generatedActiveCodesList.styled';

function GeneratedActiveCodesList({
  hideGoBackButton,
  codesList,
  onGoBackHandler,
  currency,
  isCurrencySymbol,
  alertContext
}) {
  const {t} = useTranslation();

  const [selectedCodeIndex, setSelectedCodeIndex] = useState(0);

  const moreThenOneCodes = useMemo(() => codesList.length > 1, [codesList]);

  const goToLeftHandler = () => {
    setSelectedCodeIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const goToRightHandler = () => {
    setSelectedCodeIndex((prev) => (prev < codesList.length - 1 ? prev + 1 : prev));
  };

  return (
    <div>
      <StyledGeneratedCodesField widthoutBakcgroundAndBorder>
        <StyledFlexContainerDynamic gap="8px">
          <StyledIndicatorWrapper>
            <BarCodeIcon />
          </StyledIndicatorWrapper>
          <StyledBarCodeMessage>{`${moreThenOneCodes ? `${codesList.length}` : ''} ${t(moreThenOneCodes ? 'activebarcodes' : 'activebarcode')}`}</StyledBarCodeMessage>
        </StyledFlexContainerDynamic>
        {moreThenOneCodes ? (
          <StyledFlexContainerDynamic gap="4px" flex="unset">
            <StyledIconButtonWithBg type="button" onClick={goToLeftHandler}><StyledIconSmall icon="arrowLeft" fontSize="11px" /></StyledIconButtonWithBg>
            <StyledBarCodeMessage>{`${selectedCodeIndex + 1}/${codesList.length}`}</StyledBarCodeMessage>
            <StyledIconButtonWithBg type="button" onClick={goToRightHandler}><StyledIconSmall icon="arrowRight" fontSize="11px" /></StyledIconButtonWithBg>
          </StyledFlexContainerDynamic>
        ) : null}
      </StyledGeneratedCodesField>
      <StyledFlexContainerDynamic direction="column" gap="24px">
        <GenerateCode
          codeDetailsData={codesList[selectedCodeIndex].data}
          translations={{}}
          setDataName={() => {}}
          alertContext={alertContext}
          currency={currency}
          isCurrencySymbol={isCurrencySymbol}
        />
        {hideGoBackButton ? null : (
          <StyledLinkButton
            type="button"
            alignSelf="center"
            onClick={onGoBackHandler}
          >
            {t('backToCodeGenerator'.toLowerCase())}
          </StyledLinkButton>
        )}
      </StyledFlexContainerDynamic>
    </div>
  );
}

GeneratedActiveCodesList.propTypes = {
  hideGoBackButton: PropTypes.bool,
  codesList: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  alertContext: PropTypes.object.isRequired,
  onGoBackHandler: PropTypes.func.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired
};

GeneratedActiveCodesList.defaultProps = {
  hideGoBackButton: false
};

export default memo(GeneratedActiveCodesList);
