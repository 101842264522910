import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import PaymentItemWebTemplateTwo from './paymentItemTemplateTwo';
import Search from '../../../search/search';
import NavigationTabsWeb from '../../../navigationTabs/navigationTabsWeb/navigationTabsWeb';
import {DEVICE_TYPE, groupNameMapping} from '../../../../constants/common';
import BonusBanner from '../../../bonusBanner/bonusBanner';

/* Styled Components */
import {StyledDashedHr} from '../../../../styledComponents/button/button.styled';
import {
  StyledGroupName,
  StyledLine,
  StyledPsWrapper,
  StyledPsWrapperMobile
} from './paymentItemTemplateTwo.styled';
import {StyledFlexContainerDynamic} from '../../../paymentBonusesList/paymentBonusesListWeb.styled';
import {componentMapping} from '../../../../helpers/styled';

function ListTemplateTwo({
  paymentData,
  handlePaymentSystemDetailsPage,
  currency,
  isCurrencySymbol,
  enableSearch,
  isFavoriteGroup,
  onFavoriteClickHandler,
  customizationTheme,
  deviceType,
  hasTabs,
  activeTab,
  setActiveTab,
  translations,
  isBonusBanner,
  bonusBannerUrl,
  goToBonusHandler,
  isMobileVersion
}) {
  const [tabVersion, setTabVersion] = useState(customizationTheme?.navigationTabVersion?.value || 'versionOne');

  useEffect(() => {
    if (customizationTheme?.navigationTabVersion?.value) {
      setTabVersion(customizationTheme.navigationTabVersion.value);
    }
  }, [customizationTheme?.navigationTabVersion?.value]);

  const StyledPsWrapperComponent = useMemo(
    () => (isMobileVersion ? StyledPsWrapperMobile : StyledPsWrapper),
    [isMobileVersion]
  );

  const handleTabClick = (event) => {
    const {name} = event.currentTarget;
    setActiveTab(name);
  };

  const renderGroupName = (groupId) => {
    if (groupId !== 0) {
      return (
        <StyledGroupName>
          {translations[groupNameMapping[groupId]?.toLowerCase()]}
          <StyledLine />
        </StyledGroupName>
      );
    }
    return paymentData.length > 1 ? <StyledDashedHr hasMarginBottom /> : null;
  };

  // Get the components based on the templateType
  const {
    StyledMainWrapper
  } = componentMapping[deviceType] || DEVICE_TYPE.WEB;

  return (
    <StyledMainWrapper>
      {(hasTabs && paymentData?.length) || enableSearch ? (
        <div>
          {hasTabs && paymentData?.length && (
            <NavigationTabsWeb
              tabVersion={tabVersion}
              handleTabClick={handleTabClick}
              activeTab={activeTab}
              deviceType={deviceType}
            />
          )}
          {enableSearch && <Search />}
        </div>
      ) : null}
      {isBonusBanner ? (
        <BonusBanner bannerUrl={bonusBannerUrl} goToBonusHandler={goToBonusHandler} />
      ) : null}
      <StyledFlexContainerDynamic
        direction="column"
        gap={isMobileVersion ? '16px' : '24px'}
      >
        {paymentData?.map((pasItem, indexOne) => (
          <section key={`${pasItem.groupId}:${indexOne}`}>
            {renderGroupName(pasItem.groupId)}
            <StyledPsWrapperComponent>
              {pasItem?.cashierPaymentSystem?.map((paymentInfo, indexTwo) => (
                <PaymentItemWebTemplateTwo
                  isCurrencySymbol={isCurrencySymbol}
                  key={`${paymentInfo?.paymentSystemId}:${indexTwo}`}
                  paymentInfo={paymentInfo}
                  handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                  isFavoriteGroup={isFavoriteGroup}
                  onFavoriteClickHandler={onFavoriteClickHandler}
                  currency={currency}
                />
              ))}
            </StyledPsWrapperComponent>
          </section>
        ))}
      </StyledFlexContainerDynamic>
    </StyledMainWrapper>
  );
}

ListTemplateTwo.defaultProps = {
  paymentData: [],
  handlePaymentSystemDetailsPage: null,
  currency: null,
  enableSearch: false,
  onFavoriteClickHandler: null,
  isFavoriteGroup: false,
  deviceType: null,
  isBonusBanner: false,
  bonusBannerUrl: null
};

ListTemplateTwo.propTypes = {
  paymentData: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.number.isRequired,
      cashierPaymentSystem: PropTypes.arrayOf(
        PropTypes.shape({
          paymentSystemId: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ),
  handlePaymentSystemDetailsPage: PropTypes.func,
  onFavoriteClickHandler: PropTypes.func,
  currency: PropTypes.string,
  enableSearch: PropTypes.bool,
  isFavoriteGroup: PropTypes.bool,
  isCurrencySymbol: PropTypes.bool.isRequired,
  deviceType: PropTypes.number,
  customizationTheme: PropTypes.shape({
    navigationTabVersion: PropTypes.shape({
      value: PropTypes.string
    })
  }).isRequired,
  hasTabs: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  isBonusBanner: PropTypes.bool,
  bonusBannerUrl: PropTypes.string,
  goToBonusHandler: PropTypes.func.isRequired,
  isMobileVersion: PropTypes.bool.isRequired
};

export default ListTemplateTwo;
