import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode, paymentRightSideOptionsCallbacks} from '../../../../helpers/common';

/* Assets */
import {CheckBigIcon, FavoriteTemplateTwo} from '../../../../assets/icons';

/* Styled Components */
import StyledPaymentItem, {
  StyledFavoriteButton,
  StyledPaymentHint,
  StyledPaymentItemFigcaptionList,
  StyledPaymentItemLabel,
  StyledPaymentItemLogoWrapper,
  StyledPaymentItemValue,
  StyledSeparator
} from './paymentItemTemplateTwo.styled';
import {StyledFlexContainerDynamic} from '../../../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledPaymentName} from '../../../paymentsForm/templateOne/web/formWeb.styled';
import {PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES} from '../../../../constants/common';

function PaymentItemWebTemplateTwo({
  paymentInfo,
  handlePaymentSystemDetailsPage,
  isFavoriteGroup,
  onFavoriteClickHandler,
  currency,
  isCurrencySymbol,
  fromNestedPage,
  selectedItem,
  closeDropdown,
  isDropdownOption
}) {
  const {t} = useTranslation();

  return (
    <StyledPaymentItem
      onClick={() => {
        paymentRightSideOptionsCallbacks.invokeCallback(PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES.SET_OPTIONS, null);
        handlePaymentSystemDetailsPage({
          paymentSystemId: paymentInfo?.paymentSystemId,
          platformPsId: paymentInfo?.platformPsId,
          disableMethod: paymentInfo?.disableMethod,
          unavailable: paymentInfo?.unavailable,
          hasRedirect: paymentInfo?.hasRedirect,
          hasVerifiedAccount: paymentInfo?.hasVerifiedAccount
        });
        if (closeDropdown) {
          closeDropdown();
        }
      }}
      fromNestedPage={fromNestedPage}
    >
      <StyledFlexContainerDynamic gap="8px" alignItem="center">
        <StyledPaymentItemLogoWrapper>
          <img
            src={paymentInfo?.logoPath}
            alt={paymentInfo?.paymentSystemName}
            width="188"
            height="75"
          />
        </StyledPaymentItemLogoWrapper>
        <figcaption>
          <StyledPaymentName>{paymentInfo?.paymentSystemName}</StyledPaymentName>
          <StyledPaymentItemFigcaptionList isDropdownOption={isDropdownOption}>
            <li>
              <StyledPaymentItemLabel>
                {!isDropdownOption
                  && (
                    <>
                      {t(('min')?.toLowerCase())}
                      {' '}
                      -
                      {' '}
                      {t(('max')?.toLowerCase())}
                      :
                    </>
                  )}
                <StyledPaymentItemValue>
                  {paymentInfo.min}
                  {' '}
                  -
                </StyledPaymentItemValue>
                <StyledPaymentItemValue>
                  {paymentInfo.max}
                  {' '}
                  {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
                </StyledPaymentItemValue>
                {isDropdownOption && paymentInfo?.fee !== null && (
                  <StyledSeparator>|</StyledSeparator>
                )}
              </StyledPaymentItemLabel>
            </li>
            {paymentInfo?.fee !== null ? (
              <li>
                <StyledPaymentItemLabel>
                  {!isDropdownOption
                    && (
                      <>
                        {t(('fee')?.toLowerCase())}
                        :
                      </>
                    )}
                  <StyledPaymentItemValue>
                    {paymentInfo?.fee}
                    {' '}
                    %
                  </StyledPaymentItemValue>
                </StyledPaymentItemLabel>
              </li>
            ) : null}
          </StyledPaymentItemFigcaptionList>
          {paymentInfo.isNew ? (
            <StyledPaymentHint>
              {t(('new')?.toLowerCase())}
            </StyledPaymentHint>
          ) : null}
          {paymentInfo.unavailable ? (
            <StyledPaymentHint
              unavailable={paymentInfo?.unavailable}
            >
              {t(('underMaintenance')?.toLowerCase())}
            </StyledPaymentHint>
          ) : null}
          {/* <StyledPaymentHint bonus> */}
          {/*   Bonus */}
          {/* </StyledPaymentHint> */}
          {/* )} */}
        </figcaption>
      </StyledFlexContainerDynamic>
      {!fromNestedPage && isFavoriteGroup && (
        <StyledFavoriteButton
          type="button"
          isFavorite={paymentInfo.isFavorit}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onFavoriteClickHandler(paymentInfo.paymentSystemId, paymentInfo.isFavorit);
          }}
        >
          <FavoriteTemplateTwo />
        </StyledFavoriteButton>
      )}
      {selectedItem && (
        <CheckBigIcon />
      )}
    </StyledPaymentItem>
  );
}

PaymentItemWebTemplateTwo.defaultProps = {
  paymentInfo: null,
  handlePaymentSystemDetailsPage: null,
  isFavoriteGroup: true,
  onFavoriteClickHandler: null,
  currency: null,
  isCurrencySymbol: null,
  fromNestedPage: false,
  selectedItem: false,
  closeDropdown: null,
  isDropdownOption: false
};

PaymentItemWebTemplateTwo.propTypes = {
  paymentInfo: PropTypes.object,
  handlePaymentSystemDetailsPage: PropTypes.func,
  isFavoriteGroup: PropTypes.bool,
  onFavoriteClickHandler: PropTypes.func,
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool,
  fromNestedPage: PropTypes.bool,
  selectedItem: PropTypes.bool,
  closeDropdown: PropTypes.func,
  isDropdownOption: PropTypes.bool
};

export default PaymentItemWebTemplateTwo;
