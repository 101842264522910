import styled from 'styled-components';

const StyledFilterWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 100%;
  
  div {
    width: 100%;
  }
`;

const StyledSearchInputWrapper = styled(StyledFilterWrapper)`
  max-width: 100%;
  margin-bottom: 16px;

  svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({theme}) => theme.generalIconsColor};
    pointer-events: none;

    path {
      fill: currentColor;
    }
  }
`;

export {
  StyledFilterWrapper,
  StyledSearchInputWrapper
};
