import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {CopyField} from '../paymentsForm/controlsType/commonControlsType';

// styled components
import {StyledListContent, StyledListTitle, StyledListWrapper} from '../../styledComponents/list/list.styled';
import StyledButton, {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledCardHeaderTitle} from '../paymentsForm/templateOne/web/formWeb.styled';
import {StyledSelectOptionsHeader} from '../../styledComponents/select/select.styled';
import {StyledCodeBorderWrapped, StyledCodeLabel, StyledCodeWrapper} from '../generateCode/generateCode.styled';
import {CODE_TYPE} from '../../constants/common';
import {StyledFlexContainerDynamic} from '../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledHistoryDetailsDataWrapper} from '../paymentsHistory/templateOne/web/historyWeb.styled';

function SafetyPayHistoryDetails({
  paymentData,
  onPayHandler,
  onCloseHandler,
  isMobile,
  alertContext
}) {
  const {t} = useTranslation();

  const {
    howto_pay_steps: guide,
    location_url: redirectUrl,
    payment_instructions: paymentInstructions
  } = paymentData || {};

  const [paymentReference, transactionId, qrCode, qrUrl] = useMemo(() => {
    const reference = paymentInstructions?.find((item) => item?.name === 'PaymentReference')?.value;
    const id = paymentInstructions?.find((item) => item?.name === 'TransactionID')?.value;
    const code = paymentInstructions?.find((item) => item?.name === 'QRCodeImageBase64')?.value;
    const url = paymentInstructions?.find((item) => item?.name === 'QRCodeUrl')?.value;

    return [reference, id, code, url];
  }, [paymentInstructions]);

  return (
    <div>
      {isMobile ? null : (
        <StyledSelectOptionsHeader>
          <StyledButtonWithoutBg type="button" onClick={onCloseHandler} isPositionLeft>
            <StyledIconSmall icon="close" fontSize="11px" />
          </StyledButtonWithoutBg>
          <StyledCardHeaderTitle>{t('order')}</StyledCardHeaderTitle>
        </StyledSelectOptionsHeader>
      )}
      <StyledFlexContainerDynamic direction="column" gap="24px" flex="unset">
        {!isMobile && qrCode ? (
          <StyledFlexContainerDynamic direction="column" alignItem="center" gap="12px">
            <StyledCodeLabel>{t('scantopay')}</StyledCodeLabel>
            <StyledCodeBorderWrapped
              withoutMarginBottom
              codeType={CODE_TYPE.QR_CODE}
            >
              <StyledCodeWrapper
                withoutPadding
                codeType={CODE_TYPE.QR_CODE}
              >
                <img src={qrCode} alt="" />
              </StyledCodeWrapper>
            </StyledCodeBorderWrapped>
          </StyledFlexContainerDynamic>
        ) : null}
        <StyledHistoryDetailsDataWrapper>
          <CopyField
            copyValue={paymentReference || transactionId}
            dataName={paymentReference ? 'paymentReference' : 'transactionId'}
            alertHandler={() => { alertContext.success(t('copied')); }}
          />
        </StyledHistoryDetailsDataWrapper>
        <StyledListWrapper>
          <StyledListTitle>{t(('guideTitle')?.toLowerCase())}</StyledListTitle>
          <StyledListContent>
            {guide?.map((item) => (
              <li key={item.step_number}>{item.step_instruction}</li>
            ))}
          </StyledListContent>
        </StyledListWrapper>
        {redirectUrl || qrUrl ? (
          <StyledButton
            type="button"
            onClick={onPayHandler(redirectUrl || qrUrl)}
          >
            {t('pay')}
          </StyledButton>
        ) : null}
      </StyledFlexContainerDynamic>
    </div>
  );
}

SafetyPayHistoryDetails.propTypes = {
  paymentData: PropTypes.object.isRequired,
  onPayHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isMobile: PropTypes.bool
};

SafetyPayHistoryDetails.defaultProps = {
  isMobile: false
};

export default memo(SafetyPayHistoryDetails);
