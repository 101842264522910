import instance from './apiService';
import {URL_PARTS} from '../constants/common';
import {
  createRequestBody, openRedirectWindow, handleDynamicParamsRequest, handleResponse, handleError
} from './serviceHelpers';

/**
 * Handles the form submission for various payment transactions, sending a request to the specified API endpoint.
 *
 * @param {function} setIsLoading - A function to set the loading status.
 * @param {function} setPaymentControlsData - A function to set payment controls data.
 * @param {function} setPaymentGeneratedCodeInfo - A function to set site payment system generated Code Info.
 * @param {boolean} hasIframe - Indicates whether the transaction uses an iframe.
 * @param {string} language - The language code (ISO format).
 * @param {function} setApplePayTransactionData - A function to set Apple Pay transaction data.setPayPalTransactionData
 * @param {function} setPayPalTransactionData - A function to set PayPal transaction data.
 * @param {function} setGooglePayTransactionData - A function to set Google Pay transaction data.
 * @param {object} transactionDataSetters - A object for transactions Data update.
 * @param {object} alertContext - The context object for displaying alerts.
 * @param {function} alertContext.error - A function to display an error alert.
 * @param {boolean} hasRedirectUrl - Indicates whether there is a redirect URL.
 * @param {object} templateTranslation - Object containing translated template strings.
 * @param {string} bonusId - The bonus id code.
 * @param {string} gaMeasurementId
 * @param {string} analyticParameter
 * @param {number} minAmountValue - The minimum value of amount if amountValues absent.
 * @param {function} setPaymentSources - A function to set site payment system generated Code Info.
 *
 * @returns {Function} - A function that handles the actual form submission based on the specified parameters.
 *
 * @throws {Error} - If the request to the API fails, an error object is thrown.
 *
 * @example
 * const setIsLoading = (isLoading) => {
 *   // Set loading status in the UI
 * };
 *
 * const setPaymentControlsData = (paymentControls) => {
 *   // Set payment controls data in the UI
 * };
 *
 *
 * const hasIframe = true;
 * const language = 'en';
 * const setApplePayTransactionData = (applePayTransactionData) => {
 *   // Set Apple Pay transaction data in the UI
 * };
 * const alertContext = {
 *   error: (errorMessage) => {
 *     // Display an error alert in the UI
 *     console.error(errorMessage);
 *   },
 *   success: (successMessage) => {
 *     // Display a success alert in the UI
 *     console.log(successMessage);
 *   }
 * };
 * const hasRedirectUrl = true;
 * const templateTranslation = {
 *   successTransaction: 'Transaction successful!',
 *   // Other translated template strings
 * };
 *
 * const handleSubmit = handleSubmitForm(
 *   setIsLoading,
 *   setPaymentControlsData,
 *   setSitePaymentSystemControls,
 *   hasIframe,
 *   language,
 *   setApplePayTransactionData,
 *   alertContext,
 *   hasRedirectUrl,
 *   templateTranslation,
 *   bonusId
 * );
 *
 * handleSubmit(
 *   'https://example.com/api/submitTransaction',
 *   '123',
 *   'paymentSystem123',
 *   'platformPaymentSystem456',
 *   'site123',
 *   'USD',
 *   'purchase',
 *   { /* Form values object * / },
 *   'xyzToken',
 *   true,
 *   secondaryUrl,
 *   2,
 *   'post',
 *   '123'
 * );
 */

export const handleSubmitForm = (
  setIsLoading,
  setPaymentControlsData,
  setPaymentGeneratedCodeInfo,
  hasIframe,
  language,
  setApplePayTransactionData,
  setPayPalTransactionData,
  setGooglePayTransactionData,
  transactionDataSetters,
  alertContext,
  hasRedirectUrl,
  templateTranslation,
  gaMeasurementId,
  minAmountValue,
  setPaymentSources,
  analyticParameter
) => async({
  url,
  userId,
  paymentSystemId,
  platformPaymentSystemId,
  siteId,
  currency,
  operationType,
  payloadValues,
  token,
  isMultiStep,
  secondaryUrl,
  dynamicUrlProps,
  cancelActiveBonus,
  setConektaTransactionData,
  setAMLRedirectionMessage,
  selectedBonusId: bonusId,
  resetFormHandler
// eslint-disable-next-line consistent-return
}) => {
  setIsLoading(true);

  let w1 = '';

  try {
    const requestBody = createRequestBody({
      url,
      token,
      userId,
      siteId,
      bonusId,
      currency,
      language,
      operationType,
      payloadValues,
      minAmountValue,
      paymentSystemId,
      gaMeasurementId,
      cancelActiveBonus,
      analyticParameter,
      platformPaymentSystemId
    });

    /**
     * Local solution for account verification flow  success massage show
     * */
    const isAccountVerifyApi = url.includes(URL_PARTS.SET_MERCHANT_USER_BANK_ACCOUNT);

    if (isAccountVerifyApi) {
      const response = await instance.post(url, requestBody);

      if (response?.status === 200) {
        alertContext?.success('Successfully applied');
      }

      if (typeof resetFormHandler === 'function') resetFormHandler();
      return;
    }

    w1 = openRedirectWindow({
      hasRedirectUrl, hasIframe, isTransactionCreateApi: url.includes(URL_PARTS.TRANSACTION_CREATE)
    });

    if (dynamicUrlProps.withParams === 'true') {
      /**
       * Handles the case where dynamic parameters are used in the payment control.
       *
       * If `dynamicUrlProps.withParams` is `true`, the function skips the
       * transaction creation API call and instead invokes a specific method type
       * for handling dynamic parameter requests. This approach allows for tailored
       * behavior based on the payment control's requirements, such as in cases like
       * the Depozitron flow.
       *
       *  * In this case, a GET API call is made to fetch specific locations by amount
       *  * for withdrawals. The response from this GET request may then inform the next
       *  * steps in the flow, such as updating the control steps dynamically or
       *  * presenting options to the user.
       *
       * The function makes use of `handleDynamicParamsRequest` to process the
       * request dynamically. Additionally, it may modify the payment control step
       * as needed to align with the flow's requirements.
       *
       * @param {string} url - The endpoint URL to make the API request.
       * @param {number|string} siteId - The ID of the site to associate the request with.
       * @param {string} operationType - The type of operation being performed (e.g., "deposit", "withdrawal").
       * @param {Object} dynamicUrlProps - Contains properties for dynamically generating the request URL and parameters.
       * @param {boolean} dynamicUrlProps.withParams - Indicates whether the request uses dynamic parameters.
       * @param {string} [dynamicUrlProps.methodType] - The HTTP method type to use for the dynamic request (e.g., "POST", "GET").
       * @param {number|string} paymentSystemId - The ID of the payment system being used.
       * @param {Function} setPaymentControlsData - Callback function to update the payment control data.
       *
       * @returns {Promise<void>} - Resolves once the dynamic request is successfully processed.
       *
       * @example
       * // When dynamicUrlProps.withParams is true, call a dynamic method type:
       * await handleDynamicParamsRequest(url, siteId, operationType, dynamicUrlProps, paymentSystemId, setPaymentControlsData);
       *
       * // For example, in Depozitron flow:
       * // - Dynamic URL is built using specific params.
       * // - Control steps may update dynamically based on the flow requirements.
       */
      await handleDynamicParamsRequest(url, siteId, operationType, dynamicUrlProps, paymentSystemId, setPaymentControlsData);
    } else {
      const response = await instance.post(url, requestBody);

      handleResponse({
        url,
        w1,
        siteId,
        userId,
        currency,
        response,
        language,
        isMultiStep,
        secondaryUrl,
        alertContext,
        operationType,
        hasRedirectUrl,
        minAmountValue,
        paymentSystemId,
        setPaymentSources,
        templateTranslation,
        setPaymentControlsData,
        transactionDataSetters,
        setConektaTransactionData,
        setPaymentGeneratedCodeInfo,
        amountValues: payloadValues.amount,
        resetFormHandler
      });
    }
  } catch (error) {
    handleError({
      w1,
      error,
      hasIframe,
      alertContext,
      operationType,
      hasRedirectUrl,
      setAMLRedirectionMessage,
      isTransactionCreateApi: url.includes(URL_PARTS.TRANSACTION_CREATE)
    });
    // throw error;
  } finally {
    setIsLoading(false);
  }
};
