import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// styled
import {
  StyledSelectAccountStatusLabel,
  StyledSelectOption,
  StyledSelectOptionsWrapper,
  StyledSelectText
} from '../../../styledComponents/select/select.styled';
import {StyledHistoryUploadedWrapper, StyledUploadedList} from '../../../styledComponents/list/list.styled';
import {StyledPaymentItemLabel, StyledPaymentItemValue} from '../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {ACCOUNT_VERIFICATION_STATUSES_COLORS} from '../../../constants/styled';
import {
  StyledCardNumber,
  StyledFlipCard,
  StyledFlipCardContainer
} from '../../tabs/tabs.styled';
import {StyledPaymentCard, StyledPaymentCardPicture} from '../../bonusPaymentItem/bonusPaymentItem.styled';
import {StyledFlexContainerDynamic} from '../../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledButtonWithoutBg} from '../../../styledComponents/button/button.styled';
import {DeleteIcon} from '../../../assets/icons';

function VerificationHistoryContainer({historyData}) {
  const {t} = useTranslation();

  return (
    <StyledSelectOptionsWrapper>
      {historyData.map(({uploadedDate, merchantUserAccountNumberStatus}) => (
        merchantUserAccountNumberStatus?.map(({accountNumber, accountStatus}) => (
          <StyledFlipCardContainer key={`${uploadedDate}-${accountNumber}-${accountStatus}`}>
            <StyledFlipCard showConfirmation={false}>
              <StyledPaymentCard
                as="figure"
                isFlipCard
              >
                <StyledFlexContainerDynamic
                  gap="8px"
                  alignItem="center"
                >
                  <figcaption>
                    <StyledCardNumber>{accountNumber}</StyledCardNumber>
                    <div>
                      <span>{`${t(('uploadedDate')?.toLowerCase())}`}</span>
                      <span>{uploadedDate}</span>
                    </div>
                    <StyledSelectAccountStatusLabel
                      backgroundColor={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.backgroundColor}
                      color={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.color}
                    >
                      {t((accountStatus)?.toLowerCase())}
                    </StyledSelectAccountStatusLabel>
                  </figcaption>
                </StyledFlexContainerDynamic>
              </StyledPaymentCard>
            </StyledFlipCard>
          </StyledFlipCardContainer>
        ))
      ))}
    </StyledSelectOptionsWrapper>
  );
}

VerificationHistoryContainer.propTypes = {
  historyData: PropTypes.array.isRequired
};

export default VerificationHistoryContainer;
