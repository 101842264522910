import styled, {css} from 'styled-components';
import {
  flexColumnContainer,
  flexContainer,
  fontStyle,
  getBorderRadius
} from '../../helpers/styled';

/**
 * Styled components for BonusPaymentItem
 * in the first page small logo and
 * additional button with their container
 * */
const StyledCardList = styled.ul`
  display: flex;
  grid-gap: 4px;
  margin-top: 4px;
`;

/* Every small ps item with white bg */
const StyledCardListItem = styled.li`
  height: 31px;
  width: 63px;
  ${flexContainer()};
  border-radius: 4px;
  background-color: ${({theme}) => theme.generalColorNeutralMinimum};
  color: ${({theme}) => theme.generalColorNeutralMaximum};
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
`;

const StyledCardListIContainer = styled.div`
  position: relative;
`;

/**
 * Nested big payment card with additional
 * info when open dropdown or
 * go inside bonus info page
 * */
const StyledOptionsContainer = styled.div`
  position: absolute;
  right: -22px;
  width: 324px;
  border-radius: ${(props) => getBorderRadius(props, 'card')};
  border: 1px solid ${({theme}) => `${theme.textContentColor}30`};
  background-color: white;
  overflow: hidden;
  z-index: 99;
    ${(props) => (`${props.openPosition}: 40px`)};  
`;

const StyledOverflowContainer = styled.div`
  max-height: 260px;
  ${flexColumnContainer()};
  grid-gap: 2px;
  padding: 4px;
  overflow: auto;
`;

const StyledPaymentCard = styled.figure`
  position: relative;
  width: 100%;
  height: 78px;
  min-height: 78px;
  ${flexContainer({justifyContent: 'space-between', gap: '8px'})};
  padding: 8px 12px 8px 8px;
  border-radius: ${(props) => getBorderRadius(props, 'card')};
  background-color: ${({theme}) => theme.generalCardBackgroundColor};

  ${({formDropdown}) => formDropdown && css`
    border-bottom: 2px solid transparent;
  `}
  
  // ${({isOpen}) => isOpen && css`
  //   border-bottom: 2px solid  ${({theme}) => theme.brandColor};
  // `}

  ${({isFlipCard}) => isFlipCard && css`
    position: absolute;
    backface-visibility: hidden;
  `}

  ${({isPointer}) => isPointer && css`
    cursor: pointer;
  `}
`;

const StyledPaymentCardPicture = styled.picture`
  height: 62px;
  width: 80px;
  ${flexContainer()};
  background-color: ${({theme}) => theme.generalColorNeutralMinimum};
  border-radius: ${(props) => getBorderRadius(props, 'cardLogoContainer')};
  
  img, svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const StyledPaymentCardInfo = styled.figcaption`
  ${flexContainer()};
  flex: 1;
`;

const StyledPaymentCardInfoContent = styled.div`
  flex: 1;
  display: inline-block;
  justify-content: space-between;
`;

const StyledPaymentHintForBonusItem = styled.div`
  ${flexContainer()};
  display: inline-flex;
  padding: 2px 4px;
  background-color: ${({theme}) => theme.cardNewLabelBackgroundColor};
  color: ${({theme}) => theme.cardNewLabelTextColor};
  ${fontStyle('semiBold')};
  font-size: 10px;
  line-height: normal;
  border-radius: ${(props) => getBorderRadius(props, 'hint')};
    
  ${(props) => props.unavailable && css`
    background-color: ${() => props.theme.cardUnderMaintenanceLabelBackgroundColor};
    color: ${() => props.theme.cardUnderMaintenanceLabelTextColor};
`};
`;

export {
  StyledCardList,
  StyledCardListItem,
  StyledPaymentCard,
  StyledPaymentCardPicture,
  StyledPaymentCardInfo,
  StyledPaymentCardInfoContent,
  StyledCardListIContainer,
  StyledOptionsContainer,
  StyledOverflowContainer,
  StyledPaymentHintForBonusItem
};
