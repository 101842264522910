import styled, {css} from 'styled-components';
import {flexContainer, fontStyle} from '../../../helpers/styled';
import {StyledListItem} from '../../../styledComponents/list/list.styled';
import {StyledPaymentName} from '../../paymentsForm/templateOne/web/formWeb.styled';

const StyledPaymentDetailsItemWrapper = styled.div`
  background-color: ${({theme}) => theme.surface.surfaceTertiary};
  border-radius: 8px;
  
  ${({insideForm}) => insideForm && css`
    background-color: ${({theme}) => theme.surface.surfaceSecondary};
    
    li:first-child {
      border-top: 0;
    }
  `}
`;

const StyledAccordionHeader = styled.header` 
  ${flexContainer({justifyContent: 'space-between'})};
  height: 40px;
  padding: 8px 12px;
`;

const StyledAccordionTitle = styled.h3` 
  ${fontStyle('regular')};
  text-transform: uppercase;
  color: ${(props) => props.theme.text.text};
  cursor: pointer;
`;

const StyledAccordionContent = styled.div` 
  padding: 8px 12px;
  border-top: 1px solid ${({theme}) => theme.border.border};
`;

const StyledDetailsListItem = styled(StyledListItem)`  
  height: 40px;
  border-top: 1px solid ${({theme}) => theme.border.border};
  padding: 8px 12px;
`;

const StyledBackToText = styled.span`
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeLarge};
  color: ${(props) => props.theme.text.text};
`;

const StyledDetailsListItemTitle = styled(StyledPaymentName)`
  display: flex;
  align-items: center;
  margin-bottom: -12px;
  grid-gap: 8px;
  
  svg path {
    fill: ${({theme}) => theme.surface.surfaceInvert};
  }
`;

export {
  StyledPaymentDetailsItemWrapper,
  StyledAccordionHeader,
  StyledAccordionTitle,
  StyledAccordionContent,
  StyledDetailsListItem,
  StyledBackToText,
  StyledDetailsListItemTitle
};
