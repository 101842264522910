import React, {
  useContext, useState, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../../../helpers/common';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import PaymentItemWebTemplateTwo from '../../../paymentsList/templateTwo/common/paymentItemTemplateTwo';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {groupNameMapping} from '../../../../constants/common';
import {ArrowDown} from '../../../../assets/icons';

/* Styled Components */
import {StyledDashedHr} from '../../../../styledComponents/button/button.styled';
import {StyledFlexContainerDynamic} from '../../../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledPaymentName} from '../../templateOne/web/formWeb.styled';
import StyledPaymentDropdownWrapper, {
  StyledCardGroupsWrapper,
  StyledPaymentCardsList
} from './paymentCardWeb.styled';
import {
  StyledPaymentCard,
  StyledPaymentCardPicture
} from '../../../bonusPaymentItem/bonusPaymentItem.styled';
import {
  StyledDropdownIconContainer,
  StyledGroupName,
  StyledLine,
  // StyledPaymentHint,
  StyledPaymentItemFigcaptionList,
  StyledPaymentItemLabel,
  StyledPaymentItemValue,
  StyledPsWrapperMobile
} from '../../../paymentsList/templateTwo/common/paymentItemTemplateTwo.styled';

function PaymentCardWeb({
  // paymentInfo,
  currency,
  isCurrencySymbol,
  translations,
  paymentData
}) {
  const paymentSystemDetail = useContext(SelectedPaymentDetailsContext);
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const {handlePaymentSystemDetailsPage} = useContext(PaymentFormCallbacksContext) ?? {};
  const dropdownRef = useRef(null);

  const handleExpandPaymentsList = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <StyledPaymentDropdownWrapper ref={dropdownRef}>
      <StyledPaymentCard
        isPointer
        as="figure"
        onClick={handleExpandPaymentsList}
        isOpen={isOpen}
        formDropdown
      >
        <StyledFlexContainerDynamic gap="8px" alignItem="center" overflow="hidden">
          <StyledPaymentCardPicture>
            <img
              src={paymentSystemDetail?.logoPath}
              alt={paymentSystemDetail?.paymentSystemName}
              width="188"
              height="75"
            />
          </StyledPaymentCardPicture>
          <figcaption>
            <StyledPaymentName>{paymentSystemDetail?.paymentSystemName}</StyledPaymentName>
            <StyledPaymentItemFigcaptionList>
              <li>
                <StyledPaymentItemLabel>
                  {t(('min')?.toLowerCase())}
                  {' '}
                  -
                  {' '}
                  {t(('max')?.toLowerCase())}
                  :
                  <StyledPaymentItemValue>
                    {paymentSystemDetail?.min}
                    {' '}
                    -
                  </StyledPaymentItemValue>
                  <StyledPaymentItemValue>
                    {paymentSystemDetail?.max}
                    {' '}
                    {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
                  </StyledPaymentItemValue>
                </StyledPaymentItemLabel>
              </li>
              {paymentSystemDetail?.fee !== null ? (
                <li>
                  <StyledPaymentItemLabel>
                    {t(('fee')?.toLowerCase())}
                    :
                    <StyledPaymentItemValue>
                      {paymentSystemDetail?.fee}
                      {' '}
                      %
                    </StyledPaymentItemValue>
                  </StyledPaymentItemLabel>
                </li>
              ) : null}
            </StyledPaymentItemFigcaptionList>
            {/* <StyledPaymentHint */}
            {/*   bonus */}
            {/* > */}
            {/*   {t('bonus'.toLowerCase())} */}
            {/* </StyledPaymentHint> */}
          </figcaption>
        </StyledFlexContainerDynamic>
        <StyledDropdownIconContainer isOpen={isOpen}>
          <ArrowDown />
        </StyledDropdownIconContainer>
      </StyledPaymentCard>
      {isOpen ? (
        <StyledPaymentCardsList>
          <StyledCardGroupsWrapper>
            {paymentData?.map((pasItem, indexOne) => (
            // eslint-disable-next-line react/no-array-index-key
              <section key={`${pasItem.groupId}:${indexOne}`}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {pasItem.groupId !== 0 ? (
                  <StyledGroupName>
                    {translations[groupNameMapping[pasItem?.groupId]?.toLowerCase()]}
                    <StyledLine />
                  </StyledGroupName>
                ) : paymentData.length > 1 ? <StyledDashedHr hasMarginBottom /> : null}
                <StyledPsWrapperMobile>
                  {/* eslint-disable-next-line no-shadow */}
                  {pasItem?.cashierPaymentSystem?.map((paymentInfo, indexTwo) => (
                    <PaymentItemWebTemplateTwo
                      isCurrencySymbol={isCurrencySymbol}
                    /* eslint-disable-next-line react/no-array-index-key */
                      key={`${paymentInfo?.paymentSystemId}:${indexTwo}`}
                      paymentInfo={paymentInfo}
                      handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                      currency={currency}
                      fromNestedPage
                      selectedItem={paymentSystemDetail?.paymentSystemId === paymentInfo.paymentSystemId}
                      closeDropdown={handleCloseDropdown}
                      isDropdownOption
                    />
                  ))}
                </StyledPsWrapperMobile>
              </section>
            ))}
          </StyledCardGroupsWrapper>
        </StyledPaymentCardsList>
      ) : null}
    </StyledPaymentDropdownWrapper>
  );
}

PaymentCardWeb.defaultProps = {
  // paymentInfo: null,
  currency: null,
  isCurrencySymbol: null,
  translations: null,
  paymentData: null
};

PaymentCardWeb.propTypes = {
  // paymentInfo: PropTypes.object,
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool,
  translations: PropTypes.array,
  paymentData: PropTypes.array
};

export default PaymentCardWeb;
