import styled from 'styled-components';
import StyledPaymentFormContainer, {
  StyledPaymentFormInfoWrapper,
  StyledSectionHeaderWrapper
} from '../web/formWeb.styled';

const StyledPaymentFormInfoWrapperMobile = styled(StyledPaymentFormInfoWrapper)`
  padding: 24px 12px;
  overflow: auto;
  height: 100%;
`;

const StyledPaymentFormContainerMobile = styled(StyledPaymentFormContainer)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const StyledSectionHeaderWrapperMobile = styled(StyledSectionHeaderWrapper)`
  justify-content: space-between;
  padding: 0 12px;
  cursor: default;

  button {
    width: auto;
  }

  span {
    flex: unset;
    margin-left: 0;
  }
`;

export default StyledPaymentFormContainerMobile;

export {
  StyledPaymentFormInfoWrapperMobile,
  StyledSectionHeaderWrapperMobile
};
