import React, {memo} from 'react';
import PropTypes from 'prop-types';
import RightSideDeletableCardOption from './rightSideDeletableCardOption';

// styled
import {StyledFlexContainerDynamic} from '../paymentBonusesList/paymentBonusesListWeb.styled';

function PaymentSelectDeletableOptions({optionsList}) {
  return (
    <StyledFlexContainerDynamic direction="column" gap="8px" overflow="initial">
      {optionsList.map((option) => (
        <RightSideDeletableCardOption key={option.name} {...option} />
      ))}
    </StyledFlexContainerDynamic>
  );
}

PaymentSelectDeletableOptions.propTypes = {
  optionsList: PropTypes.array.isRequired
};

export default memo(PaymentSelectDeletableOptions);
