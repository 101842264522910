import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  DocsIconMedium, RemoveIcon, UploadedPdfPlaceholder
} from '../../../assets/icons';

// styled
import {StyledButtonWithoutBg} from '../../../styledComponents/button/button.styled';
import {
  StyledUploadDocumentLabelTemplateTwo,
  StyledUploadedDocumentInfo,
  StyledUploadedDocumentPreview
} from '../../../styledComponents/input/input.styled';

function UploadedFileFieldForTemplateTwo({
  htmlFor,
  // isMobile,
  fileName,
  fileIndex,
  fileFormat,
  filePreviewUrl,
  deleteFileHandler
}) {
  const {t} = useTranslation();

  const onDeleteHandler = () => {
    deleteFileHandler((prev) => ({...prev, [fileIndex]: null}));
  };

  return (
    <StyledUploadDocumentLabelTemplateTwo
      htmlFor={htmlFor}
      fileName={fileName}
    >
      {filePreviewUrl && fileName ? (
        <>
          <StyledUploadedDocumentPreview>
            {fileFormat === 'pdf' ? (
              <UploadedPdfPlaceholder />
            ) : (
              <img
                src={filePreviewUrl}
                alt={fileName}
              />
            )}
          </StyledUploadedDocumentPreview>
          <StyledButtonWithoutBg type="button" onClick={onDeleteHandler}>
            <RemoveIcon />
          </StyledButtonWithoutBg>
        </>
      ) : (
        <StyledUploadedDocumentInfo>
          <DocsIconMedium />
          <span>{`${t('document')} ${fileIndex + 1}`}</span>
        </StyledUploadedDocumentInfo>
      )}
    </StyledUploadDocumentLabelTemplateTwo>
  );
}

UploadedFileFieldForTemplateTwo.propTypes = {
  fileName: PropTypes.string,
  fileFormat: PropTypes.string,
  filePreviewUrl: PropTypes.string,
  // isMobile: PropTypes.bool.isRequired,
  htmlFor: PropTypes.string.isRequired,
  fileIndex: PropTypes.number.isRequired,
  deleteFileHandler: PropTypes.func.isRequired
};

UploadedFileFieldForTemplateTwo.defaultProps = {
  fileName: undefined,
  fileFormat: undefined,
  filePreviewUrl: undefined
};

export default memo(UploadedFileFieldForTemplateTwo);
