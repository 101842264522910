import React from 'react';
import {css} from 'styled-components';
import {
  AMOUNT_MESSAGE_STATUSES, CONTROL_STATE, HOVER_MOTIONS_LIST, TEMPLATE_TYPE
} from '../constants/common';
import {defaultTheme} from '../theme/theme';
import {
  CloseIcon,
  Error,
  ErrorTemplateTwo,
  Info,
  InfoTemplateTwo,
  InProcess,
  InProcessTemplateTwo,
  Success,
  SuccessTemplateTwo
} from '../assets/icons';
import {
  StyledPaymentFormWrapperMobile,
  StyledStatusMessageWrapperTemplateOneMobile
} from '../components/paymentsForm/templateOne/mobile/formMobile.styled';
import {StyledPaymentFormWrapper} from '../components/paymentsForm/templateOne/web/formWeb.styled';
import {
  StyledGroupedField,
  StyledInput,
  StyledInputTemplateTwo,
  StyledStatusMessageWrapper
} from '../styledComponents/input/input.styled';
import {
  StyledModalBodyTemplateOne,
  StyledModalContentTemplateOne,
  StyledModalHeaderTemplateOne
} from '../components/mobileModal/templateOneMobileModal.styled';
import {
  StyledModalBodyTemplateTwo,
  StyledModalContentTemplateTwo,
  StyledModalHeaderTemplateTwo
} from '../components/mobileModal/templateTwoMobileModal.styled';
import {
  StyledMainWrapperMobile,
  StyledMainWrapperWeb
} from '../components/paymentsList/templateTwo/common/listTemplateTwo.styled';
import {
  StyledFavoriteAmountButtonWrapperTemplateOne,
  StyledFavoriteAmountButtonWrapperTemplateTwo,
  StyledOfferedAmountButtonWrapperTemplateOne,
  StyledOfferedAmountButtonWrapperTemplateTwo
} from '../styledComponents/button/button.styled';
import {
  StyledAmountTitleTemplateOne,
  StyledAmountTitleTemplateTwo,
  StyledAmountTitleWrapperTemplateOne,
  StyledAmountTitleWrapperTemplateTwo
} from '../components/generatedActiveCodesList/generatedActiveCodesList.styled';
import {StyledStatusMessageWrapperTemplateTwo} from '../components/paymentsForm/templateTwo/web/formWeb.styled';
import {StyledIconSmall} from '../styledComponents/icon/icon.styled';
// import {generateThemeMapping} from '../configs/mainConfig';

/**
 * Define flex style for elements
 *
 * General style for all flex elements
 */
export function flexContainer({
  justifyContent = 'center',
  alignItems = 'center',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  gap = '0'
} = {}) {
  return css`
    display: flex;
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    flex-direction: ${flexDirection};
    flex-wrap: ${flexWrap};
    gap: ${gap};
  `;
}
/**
 * Define flex column style for elements
 *
 * General style for all flex column elements
 */
export function flexColumnContainer() {
  return css`
    display: flex;
    flex-direction: column;
  `;
}

/**
 * Define disable style for elements
 *
 * If the button is disabled, these styles are added
 */

export function disable() {
  return css`
    opacity: ${(props) => (props.disabled ? '0.6' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
  `;
}

/**
 * Creates text truncation CSS props.
 *
 * If text is not fitting in block, three dots will be added in the end.
 */
export function truncate() {
  return css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;
}

/**
 * Define font style for elements
 *
 * @param {String} type
 *
 */
export function fontStyle(type) {
  switch (type) {
    case 'regular':
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightRegular};
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
    case 'medium':
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightMedium};
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
    case 'semiBold':
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightSemiBold};
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
    default:
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightRegular};
        font-size: ${(props) => props.theme.typographyFontSizeExtraSmall};
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
  }
}

export function generateStatusMessageFieldColor(state, opacity) {
  const colors = new Map([
    [CONTROL_STATE.IN_PROCESS, `rgba(0, 106, 220, ${opacity})`],
    [CONTROL_STATE.FAIL, `rgba(205, 43, 49, ${opacity})`],
    [CONTROL_STATE.SUCCESS, `rgba(8, 157, 0, ${opacity})`],
    [CONTROL_STATE.INFO, `rgba(0, 106, 220, ${opacity})`],
    [AMOUNT_MESSAGE_STATUSES.ERROR, `rgba(205, 43, 49, ${opacity})`],
    [AMOUNT_MESSAGE_STATUSES.SUCCESS, `rgba(8, 157, 0, ${opacity})`],
    [AMOUNT_MESSAGE_STATUSES.DEFAULT, 'rgba(167, 167, 167, 0.19)']
  ]);

  return colors.get(state) || 'background: transparent';
}

export function generateStatusMessageFieldColorTemplateTwo(state) {
  const colors = {
    [CONTROL_STATE.IN_PROCESS]: '#FFB700',
    [CONTROL_STATE.FAIL]: '#E02A57',
    [CONTROL_STATE.SUCCESS]: '#25C196',
    [CONTROL_STATE.INFO]: '#80CDFF'
  };

  return colors[state] || 'background: transparent';
}


export const generateStatusImage = (state) => {
  switch (state) {
    case CONTROL_STATE.IN_PROCESS:
      return 'info';
    case CONTROL_STATE.FAIL:
      return 'error';
    case CONTROL_STATE.SUCCESS:
      return 'success';
    default:
      return null;
  }
};

export const iconTemplates = {
  [TEMPLATE_TYPE.TEMPLATE_ONE]: {
    1: <Success />,
    2: <Error />,
    3: <InProcess />,
    4: <Info />
  },
  [TEMPLATE_TYPE.TEMPLATE_TWO]: {
    1: <SuccessTemplateTwo />,
    2: <ErrorTemplateTwo />,
    3: <InProcessTemplateTwo />,
    4: <InfoTemplateTwo />
  }
};

export const getCardHoverStyle = (cardHoverMotion) => {
  if (cardHoverMotion === HOVER_MOTIONS_LIST.ZOOM) {
    return {
      transform: 'scale(1.1)'
    };
  } if (cardHoverMotion === HOVER_MOTIONS_LIST.BUTTON) {
    return {
      background: 'red'
    };
  }
  return null;
};

// move this function into helpers
export const mergedTheme = (customTheme) => {
  const modifiedCustomTheme = {...customTheme};

  const themeMode = customTheme?.activeMode || customTheme?.defaultMode || defaultTheme?.common?.defaultMode;
  const BRAND_COLOR = customTheme?.brandColor;
  const palette = customTheme?.palette || defaultTheme?.[themeMode]?.palette;

  const themeMapping = {
    text: {
      text: {
        dark: palette?.WHITE,
        light: palette?.DARK
      },
      textSecondary: {
        dark: palette?.ULTRA_LIGHT,
        light: palette?.MEDIUM
      },
      textTertiary: {
        dark: palette?.LIGHT,
        light: palette?.REGULAR
      },
      textInvert: {
        dark: palette?.DARKEST,
        light: palette?.LIGHTEST
      },
      textBrand: {
        dark: BRAND_COLOR,
        light: BRAND_COLOR
      }
    },
    surface: {
      surface: {
        dark: palette?.DARKEST,
        light: palette?.WHITE
      },
      surfaceSecondary: {
        dark: palette?.DARK,
        light: palette?.LIGHTEST
      },
      surfaceTertiary: {
        dark: palette?.MEDIUM,
        light: palette?.ULTRA_LIGHT
      },
      surfaceQuaternary: {
        dark: palette?.REGULAR,
        light: palette?.LIGHTEST
      },
      borderBrand: {
        dark: BRAND_COLOR,
        light: BRAND_COLOR
      },
      surfaceInvert: {
        dark: palette?.ULTRA_LIGHT,
        light: palette?.REGULAR
      },
      surfaceBrand: {
        dark: BRAND_COLOR,
        light: BRAND_COLOR
      },
      surfaceLogo: {
        dark: palette?.WHITE,
        light: palette?.WHITE
      },
      surfaceIcon: {
        dark: palette?.LIGHT,
        light: palette?.SEMI_LIGHT
      },
      surfaceFavoriteIcon: {
        dark: palette?.SEMI_LIGHT,
        light: palette?.SEMI_LIGHT
      },
      surfaceInput: {
        dark: palette?.DARK,
        light: palette?.LIGHTEST
      },
      surfaceOpacity: {
        dark: palette?.DARK,
        light: palette?.LIGHTEST
      }
    },
    border: {
      border: {
        dark: palette?.SEMI_LIGHT,
        light: palette?.LIGHT
      },
      borderSecondary: {
        dark: palette?.MEDIUM,
        light: palette?.ULTRA_LIGHT
      },
      borderBrand: {
        dark: BRAND_COLOR,
        light: BRAND_COLOR
      }
    }
  };

  const dynamicTheme = Object.keys(themeMapping).reduce((result, key) => {
    result[key] = Object.keys(themeMapping[key]).reduce((innerResult, layer) => {
      innerResult[layer] = themeMapping[key][layer][themeMode] ?? defaultTheme?.[key]?.[layer];
      return innerResult;
    }, {});
    return result;
  }, {});

  // Merge dynamicTheme into modifiedCustomTheme
  Object.assign(modifiedCustomTheme, dynamicTheme);

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(customTheme)) {
    if (typeof value === 'object' && value !== null) {
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        const newKey = `${key}${nestedKey}`;
        modifiedCustomTheme[newKey] = nestedValue ?? defaultTheme?.[key]?.[nestedKey];
      }
      delete modifiedCustomTheme[key];
    }
  }

  // Ensure final theme fills missing values from defaultTheme
  return {...defaultTheme, ...modifiedCustomTheme};
};

const removeHash = (color) => color.replace(/^#/, '');
export const generateBgImageUrl = (props, dashArray, dashArraySecondValue, dashoffset) => `data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${removeHash(props.theme.mainButtonBackgroundColor)}' stroke-width='3' stroke-dasharray='${dashArray}%2c ${dashArraySecondValue}' stroke-dashoffset='${dashoffset}' stroke-linecap='square'/%3e%3c/svg%3e`;

export const customStyles = (theme) => ({
  control: (provided, state) => ({
    ...provided,
    color: theme.inputTextColor,
    backgroundColor: theme.inputBackgroundColor,
    borderRadius: `
      ${theme.inputCornerRadiusTopLeft || 0}px
      ${theme.inputCornerRadiusTopRight || 0}px
      ${theme.inputCornerRadiusBottomRight || 0}px
      ${theme.inputCornerRadiusBottomLeft || 0}px
    `,
    border: `${theme.inputHasBorder
      ? `${theme.inputBorderWidth} solid ${theme.inputBorderColor}`
      : '0'}`,
    borderTop: theme.inputBorderTop,
    borderColor: theme.inputBorderColor,
    borderBottom: theme.inputBorderBottom,
    borderStyle: theme.inputBorderStyle,
    height: 36,
    minHeight: 'auto',
    opacity: state.isDisabled ? '0.4' : '1',
    cursor: state.isDisabled ? 'unset' : 'pointer',
    outline: state.isFocused && '0',
    boxShadow: 'unset',
    transition: 0,

    '&:hover': {
      transition: 'background-color 800ms',
      border: `1px solid ${theme.mainButtonBackgroundColor}`,
      boxShadow: 'none'
    }
  }),
  indicatorsContainer: (base) => ({
    ...base,
    color: theme.generalIconsColor,
    '& svg': {
      fill: theme.generalIconsColor
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.inputTextColor,
    font: '400 14px Roboto, sans-serif',
    paddingLeft: '0',
    marginLeft: '0'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `${theme.inputTextColor}70`,
    font: '400 14px Roboto, sans-serif'
  }),
  menu: () => ({
    position: 'absolute',
    width: '100%',
    marginTop: '2px',
    backgroundColor: theme.inputBackgroundColor,
    color: theme.inputTextColor,
    borderTopLeftRadius: `${theme.inputCornerRadiusTopLeft}px`,
    borderTopRightRadius: `${theme.inputCornerRadiusTopRight}px`,
    borderBottomLeftRadius: `${theme.inputCornerRadiusBottomLeft}px`,
    borderBottomRightRadius: `${theme.inputCornerRadiusBottomRight}px`,
    boxShadow: '0 3px 6px #7d7d7d10',
    zIndex: theme.zIndex9999,
    overflow: 'hidden'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: state.isSelected ? theme.mainButtonBackgroundColor : theme.inputTextColor,

    '&:hover': {
      backgroundColor: `${theme.inputTextColor}20`,
      color: state.isSelected ? theme.mainButtonBackgroundColor : theme.inputTextColor
    }
  }),
  dropdownIndicator: () => ({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
    color: theme.inputTextColor
  }),
  input: (provided) => ({
    ...provided,
    color: theme.inputTextColor,
    caretColor: theme.inputTextColor,
    marginLeft: '0',
    font: '400 14px Roboto, sans-serif'
  })
});

export const templateTwoCustomStyles = (theme) => ({
  control: (provided, state) => ({
    ...provided,
    color: theme.inputTextColor,
    backgroundColor: state.isFocused ? theme.generalCardBackgroundColor : theme.inputBackgroundColor,
    borderRadius: `
      ${theme.inputCornerRadiusTopLeft || 0}px
      ${theme.inputCornerRadiusTopRight || 0}px
      ${theme.inputCornerRadiusBottomRight || 0}px
      ${theme.inputCornerRadiusBottomLeft || 0}px
    `,
    border: `${theme.inputHasBorder
      ? `${theme.inputBorderWidth} dashed ${theme.inputBorderColor}`
      : '0'}`,
    borderTop: theme.inputBorderTop,
    borderColor: theme.inputBorderColor,
    borderBottom: theme.inputBorderBottom,
    borderStyle: theme.inputBorderStyle,
    height: 48,
    minHeight: 'auto',
    opacity: state.isDisabled ? '0.4' : '1',
    cursor: state.isDisabled ? 'unset' : 'pointer',
    outline: state.isFocused && '0',
    boxShadow: 'unset',
    transition: 0,

    '&:hover': {
      transition: 'background-color 800ms',
      border: `1px dashed ${theme.mainButtonBackgroundColor}`,
      boxShadow: 'none'
    }
  }),
  indicatorsContainer: (base) => ({
    ...base,
    color: theme.generalIconsColor,
    '& svg': {
      fill: theme.generalIconsColor
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.inputTextColor,
    font: '600 14px Roboto, sans-serif',
    paddingLeft: '0',
    marginLeft: '0'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `${theme.inputTextColor}70`,
    font: '400 14px Roboto, sans-serif'
  }),
  menu: () => ({
    position: 'absolute',
    width: '100%',
    marginTop: '4px',
    padding: '12px',
    backgroundColor: theme.generalCardBackgroundColor,
    color: theme.inputTextColor,
    borderTopLeftRadius: `${theme.inputCornerRadiusTopLeft}px`,
    borderTopRightRadius: `${theme.inputCornerRadiusTopRight}px`,
    borderBottomLeftRadius: `${theme.inputCornerRadiusBottomLeft}px`,
    borderBottomRightRadius: `${theme.inputCornerRadiusBottomRight}px`,
    boxShadow: '0 3px 6px #7d7d7d10',
    zIndex: theme.zIndex9999,
    overflow: 'hidden'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: state.isSelected ? theme.mainButtonBackgroundColor : theme.inputTextColor,

    '&:hover': {
      backgroundColor: `${theme.inputTextColor}20`,
      color: state.isSelected ? theme.mainButtonBackgroundColor : theme.inputTextColor
    }
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
    padding: 0,
    color: theme.inputTextColor,
    transition: 'transform 0.3s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0deg)',

    '&:hover': {
      color: theme.inputTextColor
    }
  }),
  input: (provided) => ({
    ...provided,
    color: theme.inputTextColor,
    caretColor: theme.inputTextColor,
    marginLeft: '0',
    font: '400 14px Roboto, sans-serif'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gridGap: '8px'
  })
});

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getBorderRadius = (props, buttonType = 'mainButton') => `
  ${props.theme[`${buttonType}CornerRadiusTopLeft`] || 0}px 
  ${props.theme[`${buttonType}CornerRadiusTopRight`] || 0}px 
  ${props.theme[`${buttonType}CornerRadiusBottomRight`] || 0}px 
  ${props.theme[`${buttonType}CornerRadiusBottomLeft`] || 0}px
`;

export const getBorder = (props, buttonType = 'mainButton') => {
  const borderWidth = props.theme[`${buttonType}HasBorder`]
    ? `${props.theme[`${buttonType}BorderWidth`]}px`
    : `${props.theme[`default${capitalize(buttonType)}BorderWidth`]}px`;

  const borderColor = props.theme[`${buttonType}HasBorder`]
    ? props.theme[`${buttonType}BorderColor`]
    : 'transparent'; // or a fallback value if needed

  return `${borderWidth} solid ${borderColor}`;
};

// Helper for box-shadow
export const getBoxShadow = (theme) => (theme.cardHasShadow ? `0 2px 4px ${theme.cardShadowColor}` : 'none');

// General Flex Container with Gap
export const flexContainerWithGap = (gap = '8px') => css`
  ${flexContainer};
  gap: ${gap};
`;

export const renderFieldWithCustomWidth = (renderCallback, control, groupSize) => (
  <StyledGroupedField groupSize={groupSize}>
    {renderCallback(control)}
  </StyledGroupedField>
);

// Mapping styled components based on template type
export const componentMapping = {
  1: {
    StyledModalHeader: StyledModalHeaderTemplateOne,
    StyledModalBody: StyledModalBodyTemplateOne,
    StyledModalContent: StyledModalContentTemplateOne,
    StyledInput,
    StyledMainWrapper: StyledMainWrapperWeb,
    StyledFavoriteAmountButtonWrapper: StyledFavoriteAmountButtonWrapperTemplateOne,
    StyledAmountTitleWrapper: StyledAmountTitleWrapperTemplateOne,
    StyledAmountTitle: StyledAmountTitleTemplateOne,
    StyledOfferedAmountButtonWrapper: StyledOfferedAmountButtonWrapperTemplateOne
  },
  2: {
    StyledModalHeader: StyledModalHeaderTemplateTwo,
    StyledModalBody: StyledModalBodyTemplateTwo,
    StyledModalContent: StyledModalContentTemplateTwo,
    StyledInput: StyledInputTemplateTwo,
    StyledMainWrapper: StyledMainWrapperMobile,
    StyledFavoriteAmountButtonWrapper: StyledFavoriteAmountButtonWrapperTemplateTwo,
    StyledAmountTitleWrapper: StyledAmountTitleWrapperTemplateTwo,
    StyledAmountTitle: StyledAmountTitleTemplateTwo,
    StyledOfferedAmountButtonWrapper: StyledOfferedAmountButtonWrapperTemplateTwo
  }
};

export const StyledWrapperMapping = {
  1: StyledPaymentFormWrapper,
  2: StyledPaymentFormWrapperMobile
};

export const templateAndDeviceTypeMapping = {
  1: {
    1: {
      StyledStatusMessageWrapper
    },
    2: {
      StyledStatusMessageWrapper: StyledStatusMessageWrapperTemplateOneMobile
    }
  },
  2: {
    1: {
      StyledStatusMessageWrapper: StyledStatusMessageWrapperTemplateTwo
    },
    2: {
      StyledStatusMessageWrapper: StyledStatusMessageWrapperTemplateTwo
    }
  }
};
