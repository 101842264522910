import styled, {css} from 'styled-components';
import {flexContainer, fontStyle, getBorderRadius} from '../../helpers/styled';
import {pulseAnimation} from '../../styledComponents/animations/animations.styled';

const StyledGeneratedCodesField = styled.div`
  width: 100%;
  height: 48px;
  ${flexContainer({justifyContent: 'space-between', gap: '8px'})};
  padding: 0 8px;
  border: 1px dashed ${({theme}) => theme.surface.surfaceTertiary};
  border-radius: ${(props) => getBorderRadius(props, 'input')};
  background-color: ${({theme}) => theme.surface.surfaceSecondary};

  ${(props) => props.widthoutBakcgroundAndBorder && css`
    background-color: unset;
    border: 0;
  `}

  svg path {
    fill: ${({theme}) => theme.generalIconsColor};
  }
`;

const StyledAmountTitleWrapperTemplateOne = styled.div`
  ${flexContainer()};
  margin-bottom: 24px;
  
  svg {
    display: none;
  }
`;

const StyledAmountTitleWrapperTemplateTwo = styled.div`
  width: 100%;
  height: 48px;
  ${flexContainer({justifyContent: 'flex-start', gap: '8px'})};
  
  svg path {
    fill: ${({theme}) => theme.generalIconsColor};
  }
`;

const StyledAmountTitleTemplateOne = styled.h3`
    ${fontStyle('regular')};
    color: ${({theme}) => theme.textTitleColor};
`;

const StyledAmountTitleTemplateTwo = styled(StyledAmountTitleTemplateOne)`
  ${fontStyle('semiBold')};
`;

const StyledBarCodeMessage = styled.span`
  ${fontStyle('semiBold')};
  color: ${(props) => props.theme.textContentColor};
`;

const StyledIndicatorWrapper = styled.div`
  position: relative;
  ${flexContainer()};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background: ${(props) => props.theme.alertErrorColor};
    border-radius: 50%;
    animation: ${pulseAnimation} 1.5s infinite;
  }
`;

export {
  StyledGeneratedCodesField,
  StyledAmountTitleWrapperTemplateTwo,
  StyledAmountTitleWrapperTemplateOne,
  StyledBarCodeMessage,
  StyledIndicatorWrapper,
  StyledAmountTitleTemplateOne,
  StyledAmountTitleTemplateTwo
};
