import React, {
  Fragment, useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PaymentInfoMobile from './paymentInfoMobile';
import EnhancedFormik from '../../common/enhancedFormik';
import {DEFAULT_PAYMENT_ALERTS, OPERATION_TYPE_BY_NUMBER} from '../../../../constants/common';
import Toggle from '../../../toggle/toggle';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import Slider from '../../../slider/slider';
import GenerateCode from '../../../generateCode/generateCode';
import PaymentLimits from '../../../paymentLimits/templateOne/paymentLimits';
import PaymentBalances from '../../../paymentBalances/templateOne/paymentBalances';
import RenderPaymentForm from '../../common/renderPaymentForm';
import HistoryNavigateButton from '../../../historyNavigateButton/historyNavigateButton';
import MobileModal from '../../../mobileModal/mobileModal';
import GeneratedActiveCodesList from '../../../generatedActiveCodesList/generatedActiveCodesList';

/* Styled Components */
import {
  StyledFlexContainerForPsForm,
  StyledLinkItem,
  StyledPaymentInfoParagraph,
  StyledPaymentInfoTitle
} from '../web/formWeb.styled';
import {StyledContentToggleItems, StyledFormWrapperMobile} from './formMobile.styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledMainFormAndInfoWrapperMobile, StyledPaymentInfoWrapperMobile} from './paymentInfoMobile.styled';
import {StyledGenerateCodeWrapper, StyledItemLabel} from '../../../paymentsHistory/templateOne/web/historyWeb.styled';
import {
  StyledButtonWithoutBg,
  StyledDashedHr,
  StyledHorizontalLinesButtonWrapper,
  StyledLinkButton
} from '../../../../styledComponents/button/button.styled';
import StyledSectionDivider from '../../../../styledComponents/list/list.styled';

function FormMobile({
  paymentBalances,
  paymentLimits,
  paymentGeneratedCodeInfo,
  setPaymentGeneratedCodeInfo,
  paymentDescription,
  applePaySession,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  translations,
  payPalTransactionData,
  googlePayTransactionData,
  isDefaultAvailable,
  onPaymentDefaultStatusUpdateHandler,
  deviceType,
  paymentSources,
  paymentControlsData,
  activeCodesList,
  showActiveCodesList,
  templateType,
  setUpdateFee
}) {
  const {
    operationType,
    currency,
    siteId,
    userId
  } = useContext(CashierDetailsContext);
  const {t} = useTranslation();
  const {isDefault: initialIsDefault, paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {
    setIsLoading, getGeneratedActiveCodesList, getVerificationHistory, setGeneratedActiveCodesList, openActiveCodesList
  } = useContext(PaymentFormCallbacksContext);
  const [showGeneratedCodeInfo, setShowGeneratedCodeInfo] = useState(false);
  const [dataName, setDataName] = useState('');
  const [isDefault, setIsDefault] = useState(initialIsDefault);
  const [showMenu, setShowMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [verificationHistory, setVerificationHistory] = useState([]);
  const showVerificationHistory = useMemo(() => verificationHistory?.length > 0, [verificationHistory]);

  /**
   * Toggle mobile info layer visibility
   * @param {boolean} show - Whether to show or hide the info layer
   * @param {boolean} showCodeInfo - Whether to show or hide additional payment control info
   */
  const toggleInfoLayer = (show, showCodeInfo = false) => {
    setShowDetails(show);
    setShowGeneratedCodeInfo(showCodeInfo);
  };

  const openMenuHandler = useCallback(() => {
    if (isDefaultAvailable) {
      setShowMenu(true);
    } else {
      setShowDetails(true);
    }
  }, [isDefaultAvailable]);

  const closeMenuHandler = useCallback(() => {
    setShowMenu(false);
    setShowDetails(false);
  }, []);

  const openDetailsHandler = useCallback(() => {
    setShowMenu(false);
    setShowDetails(true);
  }, []);

  const closeDetailsHandler = useCallback(() => {
    setShowDetails(false);
    setShowMenu(true);
  }, []);

  /**
   * Handle hide additional payment control info
   */
  const handleBackToDetails = () => {
    setIsLoading(true);
    getGeneratedActiveCodesList({
      paymentSystemId,
      siteId,
      userId
    }).then((response) => {
      setGeneratedActiveCodesList(response);
    }).finally(() => {
      setIsLoading(false);
    });
    setPaymentGeneratedCodeInfo([]);
    toggleInfoLayer(false);
  };

  const closePaymentInfo = useCallback(() => {
    setSeeMore(false);
  }, []);

  /**
   * Effect for handling initialIsDefault change in case of payment details change
   */
  useEffect(() => {
    setIsDefault(initialIsDefault);
  }, [initialIsDefault, paymentSystemId]);

  /**
   * Effect to handle changes in paymentGeneratedCodeInfo fields length
   */
  useEffect(() => {
    const hasGeneratedInfo = paymentGeneratedCodeInfo?.length;
    toggleInfoLayer(false, hasGeneratedInfo);
  }, [paymentGeneratedCodeInfo?.length]);

  const defaultToggleHandler = useCallback(() => {
    onPaymentDefaultStatusUpdateHandler({
      isDefault: !isDefault,
      callback: (res) => {
        if (res.data) {
          alertContext?.success(t((DEFAULT_PAYMENT_ALERTS[operationType][isDefault ? 'unSet' : 'set']).toLowerCase()));
          setIsDefault((prev) => !prev);
        } else {
          alertContext?.error(t((DEFAULT_PAYMENT_ALERTS[operationType].error).toLowerCase()));
        }
      }
    });
  }, [isDefault, alertContext]);

  const verificationHistoryContextMemoized = useMemo(() => ({
    getVerificationHistoryHandler: () => {
      getVerificationHistory({
        siteId,
        userId,
        paymentSystemId,
        setVerificationHistory
      });
    },
    clearVerificationHistoryHandler: () => {
      setVerificationHistory([]);
    },
    verificationHistory
  }), [verificationHistory]);

  const closeActiveCodeListHandler = () => {
    openActiveCodesList(false);
  };

  return (
    <StyledMainWrapper>
      <AccountVerificationHistoryContext.Provider value={verificationHistoryContextMemoized}>
        <Slider />
        <StyledFlexContainerForPsForm>
          <StyledFormWrapperMobile>
            <StyledPaymentInfoWrapperMobile>
              <PaymentInfoMobile
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                openMenuHandler={openMenuHandler}
                isMobile
                processingTimeDetail={paymentControlsData?.processingTimeDetail}
                paymentSystemName={paymentControlsData?.paymentSystemName}
              />
            </StyledPaymentInfoWrapperMobile>
            {(paymentBalances || paymentLimits) && (
              <StyledHorizontalLinesButtonWrapper>
                <StyledLinkButton
                  centerAlignedWithinHorizontalLines
                  onClick={() => setSeeMore(true)}
                >
                  {t(('seeMore')?.toLowerCase())}
                </StyledLinkButton>
              </StyledHorizontalLinesButtonWrapper>
            )}
            <StyledDashedHr />
            <StyledMainFormAndInfoWrapperMobile>
              {paymentSources?.length ? (
                <StyledGenerateCodeWrapper>
                  <GenerateCode
                    setDataName={setDataName}
                    codeDetailsData={paymentSources}
                    translations={translations}
                    alertContext={alertContext}
                    currency={currency}
                    isCurrencySymbol={isCurrencySymbol}
                  />
                </StyledGenerateCodeWrapper>
              ) : (
                <>
                  <EnhancedFormik
                    baseUrl={baseUrl}
                    applePaySession={applePaySession}
                    alertContext={alertContext}
                    payPalTransactionData={payPalTransactionData}
                    googlePayTransactionData={googlePayTransactionData}
                    formComponent={RenderPaymentForm}
                    deviceType={deviceType}
                    currency={currency}
                    isCurrencySymbol={isCurrencySymbol}
                    paymentControlsData={paymentControlsData}
                    templateType={templateType}
                    betTaxAmount={paymentBalances?.betTaxAmount === 0 ? '0' : paymentBalances?.betTaxAmount}
                    setUpdateFee={setUpdateFee}
                  />
                  {paymentControlsData?.hasHistory ? <HistoryNavigateButton /> : null}
                </>
              )}
            </StyledMainFormAndInfoWrapperMobile>
          </StyledFormWrapperMobile>
          <MobileModal
            title={t(('more')?.toLowerCase())}
            showInfo={showMenu && !showVerificationHistory}
            handleCloseInfo={closeMenuHandler}
          >
            <StyledContentToggleItems>
              <StyledItemLabel>{t(('setAsDefault')?.toLowerCase())}</StyledItemLabel>
              <Toggle
                handleToggle={defaultToggleHandler}
                isOn={isDefault}
              />
            </StyledContentToggleItems>
            <StyledContentToggleItems>
              <StyledButtonWithoutBg
                isSeeDetails
                as="button"
                onClick={openDetailsHandler}
              >
                {t(('seeDetails')?.toLowerCase())}
              </StyledButtonWithoutBg>
            </StyledContentToggleItems>
          </MobileModal>
          <MobileModal
            title={t(('details')?.toLowerCase())}
            showInfo={showDetails && !showVerificationHistory}
            handleCloseInfo={closeMenuHandler}
            handleGoBack={isDefaultAvailable ? closeDetailsHandler : null}
          >
            <>
              {paymentDescription?.desctiption ? (
                <section>
                  <StyledPaymentInfoParagraph
                    dangerouslySetInnerHTML={{
                      __html: paymentDescription?.desctiption
                    }}
                  />
                </section>
              ) : null}
              {paymentDescription?.howTo ? (
                <section>
                  <StyledPaymentInfoTitle>
                    {operationType === OPERATION_TYPE_BY_NUMBER.DEPOSIT ? t(('howToDeposit').toLowerCase()) : t(('howToWithdrawal').toLowerCase())}
                  </StyledPaymentInfoTitle>
                  {' '}
                  <StyledPaymentInfoParagraph
                    dangerouslySetInnerHTML={{
                      __html: paymentDescription?.howTo
                    }}
                  />
                </section>
              ) : null}
              {paymentDescription?.paymentInfoUrl ? (
                <section>
                  <StyledLinkItem
                    href={paymentDescription?.paymentInfoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {paymentDescription?.paymentInfoUrl}
                  </StyledLinkItem>
                </section>
              ) : null}
            </>
          </MobileModal>
          <MobileModal
            title={t((dataName)?.toLowerCase())}
            showInfo={showGeneratedCodeInfo && !showVerificationHistory}
            handleCloseInfo={handleBackToDetails}
          >
            {paymentGeneratedCodeInfo?.length ? (
              <GenerateCode
                setDataName={setDataName}
                codeDetailsData={paymentGeneratedCodeInfo}
                translations={translations}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            ) : null}
          </MobileModal>
          <MobileModal
            title={t(('paymentInfo')?.toLowerCase())}
            showInfo={seeMore && !showVerificationHistory}
            handleCloseInfo={closePaymentInfo}
          >
            <StyledSectionDivider>
              <PaymentInfoMobile
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
                isPaymentInfoInner
                isMobile
                processingTimeDetail={paymentControlsData?.processingTimeDetail}
                // paymentSystemName={paymentControlsData?.paymentSystemName}
              />
            </StyledSectionDivider>
            <>
              {paymentLimits && (
                <PaymentLimits
                  currency={currency}
                  playerCurrentLimit={paymentLimits.playerCurrentLimit}
                  playerLimit={paymentLimits.playerLimit}
                  isMobile
                />
              )}
              {paymentBalances && (
                <PaymentBalances {...paymentBalances} isMobile />
              )}
            </>
          </MobileModal>
          {activeCodesList?.length > 0 ? (
            <MobileModal
              title={t('generatedbarcode')}
              showInfo={showActiveCodesList}
              handleCloseInfo={closeActiveCodeListHandler}
            >
              <GeneratedActiveCodesList
                isMobile
                hideGoBackButton
                codesList={activeCodesList}
                onGoBackHandler={closeActiveCodeListHandler}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            </MobileModal>
          ) : null}
        </StyledFlexContainerForPsForm>
      </AccountVerificationHistoryContext.Provider>
    </StyledMainWrapper>
  );
}

FormMobile.defaultProps = {
  paymentGeneratedCodeInfo: null,
  setPaymentGeneratedCodeInfo: null,
  paymentDescription: null,
  paymentSystemId: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  paymentBalances: null,
  paymentLimits: null,
  isDefaultAvailable: false,
  onPaymentDefaultStatusUpdateHandler: null,
  paymentSources: null,
  activeCodesList: [],
  showActiveCodesList: false,
  templateType: 1
};

FormMobile.propTypes = {
  deviceType: PropTypes.number.isRequired,
  paymentGeneratedCodeInfo: PropTypes.array,
  setPaymentGeneratedCodeInfo: PropTypes.func,
  paymentDescription: PropTypes.array,
  paymentSystemId: PropTypes.number,
  applePaySession: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  paymentBalances: PropTypes.object,
  paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  onPaymentDefaultStatusUpdateHandler: PropTypes.func,
  paymentSources: PropTypes.array,
  paymentControlsData: PropTypes.object.isRequired,
  activeCodesList: PropTypes.array,
  showActiveCodesList: PropTypes.bool,
  templateType: PropTypes.number,
  setUpdateFee: PropTypes.func.isRequired
};
export default FormMobile;
