export const defaultTheme = {
  // input
  inputBackgroundColor: '#3E3E3E',
  inputTextColor: '#ECECEC',
  inputLabelColor: '#ECECEC',
  inputBorderColor: '#464646',
  inputHasLabel: false,
  inputHasBorder: true,
  inputBorderWidth: '1px',
  defaultInputBorderWidth: '0',
  inputCornerRadiusTopLeft: '12',
  inputCornerRadiusTopRight: '12',
  inputCornerRadiusBottomRight: '12',
  inputCornerRadiusBottomLeft: '12',
  inputBorderStyle: 'solid',

  /* This part should be updated after ICE */
  inputBorder: '1px',
  inputBorderTop: '',
  inputBorderBottom: '',
  inputBorderLeft: '',
  inputBorderRight: '',

  // general
  generalBackgroundColor: '#080808',
  generalFontFamily: 'Roboto',

  // Group Header
  groupHeaderBackgroundColor: '#313131',
  groupHeaderTitleColor: '#ADADAD',
  groupHeaderCornerRadiusTopLeft: '16',
  groupHeaderCornerRadiusTopRight: '16',
  groupHeaderCornerRadiusBottomRight: '16',
  groupHeaderCornerRadiusBottomLeft: '16',
  groupHeaderBorderColor: '',
  /*
  Combine them into a single variable, groupHeaderBorderWidth.
  Additionally, introduce groupHeaderBorderDirection and groupHeaderHasBorder variables.
  Following these changes, implement a function in the Cashier
  Library to detect where to add the border, specifying the width and color accordingly.
  * */
  groupHeaderHasBorder: false,
  defaultGroupHeaderBorderWidth: '0',
  groupHeaderBorderWidth: '1',
  groupHeaderBorderWidthTop: '',
  groupHeaderBorderWidthBottom: '',
  groupHeaderBorderWidthLeft: '',
  groupHeaderBorderWidthRight: '',


  // Card Label Style
  cardNewLabelBackgroundColor: '#00A410',
  cardNewLabelTextColor: '#fff',
  cardUnderMaintenanceLabelTextColor: '#ffffff',
  cardUnderMaintenanceLabelBackgroundColor: '#CB0001',

  // Card Logo Container
  cardLogoContainerCornerRadiusTopLeft: '14',
  cardLogoContainerCornerRadiusTopRight: '14',
  cardLogoContainerCornerRadiusBottomLeft: '14',
  cardLogoContainerCornerRadiusBottomRight: '14',

  // cardDesign
  cardTextTitleColor: '#ffffff',
  cardTextValueColor: '#ffffff',
  cardHasShadow: false,
  cardShadowColor: '#222222',

  cardCornerRadiusTopLeft: '16',
  cardCornerRadiusTopRight: '16',
  cardCornerRadiusBottomRight: '16',
  cardCornerRadiusBottomLeft: '16',
  cardHoverButtonBgColor: '#548787',
  cardHoverButtonTextColor: '#87914f',
  cardHasBorder: false,
  cardBorderWidth: '',
  cardBorderColor: '',
  defaultCardBorderWidth: '0',

  cardBonusLabelBackgroundColor: '#99b6ff',
  cardBonusLabelTextColor: '#003166',
  /*
  Combine them into a single variable, cardBorderWidth. Additionally, introduce cardBorderDirection and cardHasBorder variables.
  Following these changes, implement a function in the Cashier Library to detect where to add the border, specifying the width and color accordingly.
  * */
  cardDesignBorderTop: '',
  cardDesignBorderBottom: '',
  cardDesignBorderLeft: '',
  cardDesignBorderRight: '',

  // slider
  sliderShape: 'rectangle',
  sliderCornerRadiusTopLeft: '20',
  sliderCornerRadiusTopRight: '20',
  sliderCornerRadiusBottomRight: '20',
  sliderCornerRadiusBottomLeft: '20',

  sliderArrowShadowColor: '#7d7d7d',

  // depositWithdrawalButton
  mainButtonBackgroundColor: '#59C4B0',
  mainButtonTextColor: '#0B1311',
  mainButtonHasBorder: false,
  mainButtonBorderWidth: '0',
  defaultMainButtonBorderWidth: '0',
  mainButtonBorderColor: '',
  mainButtonCornerRadiusTopLeft: '12',
  mainButtonCornerRadiusTopRight: '12',
  mainButtonCornerRadiusBottomRight: '12',
  mainButtonCornerRadiusBottomLeft: '12',

  /*
  Combine them into a single variable mainButtonBorderDirection.
  Following these changes, implement a function in the Cashier Library to detect where to add the border, specifying the width and color accordingly.
  * */
  mainButtonBorderDirection: '',

  // favoriteAmountButton
  favoriteButtonBackgroundColor: '#181818',
  favoriteButtonTextColor: '#ffffff',
  favoriteButtonBorderWidth: '1',
  favoriteButtonBorderColor: '#59c4b0',
  favoriteButtonCornerRadiusTopLeft: '12',
  favoriteButtonCornerRadiusTopRight: '12',
  favoriteButtonCornerRadiusBottomRight: '12',
  favoriteButtonCornerRadiusBottomLeft: '12',
  favoriteButtonHasBorder: false,

  favoriteButtonBorderDirection: '',
  defaultFavoriteButtonBorderWidth: '0',

  /*
  In both the Cashier Library and the Admin Cashier section, group the following variables:
  * */
  // notificationsBorderTop: '1px',
  // notificationsBorderLeft: '1px',
  // notificationsBorderRight: '1px',
  // notificationsBorderBottom: '1px',

  notificationsHasBorder: true,
  notificationsBorderWidth: '1',
  notificationsBorderColor: '#006ADC',

  notificationBorderColor: '#DEDEDE',
  notificationsCornerRadiusTopLeft: '16',
  notificationsCornerRadiusTopRight: '16',
  notificationsCornerRadiusBottomRight: '16',
  notificationsCornerRadiusBottomLeft: '16',

  notificationsPosition: 'topMiddle',

  // textButton
  textButtonBackgroundColor: '#59C4B0',
  tabTextButtonColor: '#59C4B0',
  generalIconsColor: '#fff',
  // textTitleColor
  textTitleColor: '#dadfe6',
  // textContentColor
  textContentColor: '#E4E4E4',
  // hoverMotion
  hoverMotion: {},
  cardHoverMotion: 'zoom',

  // status State
  warningColor: '#f8c201',
  notificationWarningIconColor: '#f8c201',
  notificationWarningTitleColor: '#f8c201',
  notificationWarningBackgroundColor: '#f8c201',


  successColor: '#4ba031',
  notificationSuccessIconColor: '#4ba031',
  notificationSuccessTitleColor: '#666666',
  notificationSuccessBackgroundColor: '#fffff',

  infoColor: '#006ADC',

  notificationInfoBackgroundColor: '#e6f0fb',
  notificationInfoButtonColor: '#0076c2',
  warningBackgroundColor: '#ff0000',

  linkColor: '#80cdff',

  successBackgroundColor: '#1e271f',
  errorBackgroundColor: '#2c1c1c',
  infoBackgroundColor: '#17222d',

  /* Custom cashier variables */
  sliderLabelColor: '#ffffff',
  errorColor: '#cb2b31',
  labelBorderTopLeftRadius: '12px',
  labelBorderTopRightRadius: '12px',
  labelBorderBottomLeftRadius: '8px',
  labelBorderBottomRightRadius: '8px',
  labelBorderColor: '',

  hintCornerRadiusTopLeft: '4',
  hintCornerRadiusTopRight: '4',
  hintCornerRadiusBottomRight: '4',
  hintCornerRadiusBottomLeft: '4',

  mobileLogoContainerBorderTopLeftRadius: '4px',
  mobileLogoContainerBorderTopRightRadius: '4px',
  mobileLogoContainerBorderBottomLeftRadius: '4px',
  mobileLogoContainerBorderBottomRightRadius: '4px',
  mobileLogoContainerBorderColor: '#ededed',

  cardMobileLogoContainerBackground: '#ededed',

  generalCardBackgroundColor: '#313131',
  generalColorNeutralMinimum: '#ffffff',
  generalColorNeutralMaximum: '#000',
  overlayBackgroundColor: '#000000',

  // typography
  typographyFontSizeExtraSmall: '10px',
  typographyFontSizeSmall: '12px',
  typographyFontSizeMiddle: '14px',
  typographyFontSizeLarge: '16px',
  typographyFontWeightRegular: 400,
  typographyFontWeightMedium: 500,
  typographyFontWeightSemiBold: 600,
  typographyFontFamilyBase: 'Roboto, sans-serif',

  navigationTabVersion: {
    id: 1,
    label: 'Version 1',
    value: 'versionOne'
  },

  // tab versionONE GENERAL
  navigationVersionOneGeneralTextColor: '#1E2B47',
  navigationVersionOneGeneralBGColor: '#fff',
  navigationVersionOneGeneralHasBorder: true,
  navigationVersionOneGeneralBorderWidth: '1',
  navigationVersionOneGeneralBorderColor: '#1E2B47',
  navigationVersionOneGeneralBorderDirection: 'all',
  navigationVersionOneGeneralHasIcon: true,
  navigationVersionOneGeneralIconColor: '#1E2B47',
  navigationVersionOneGeneralCornerRadiusTopLeft: '30',
  navigationVersionOneGeneralCornerRadiusTopRight: '30',
  navigationVersionOneGeneralCornerRadiusBottomRight: '30',
  navigationVersionOneGeneralCornerRadiusBottomLeft: '30',
  // tab versionONE SELECTED
  navigationVersionOneSelectedTextColor: '#fff',
  navigationVersionOneSelectedBGColor: '#1E2B47',
  navigationVersionOneSelectedHasBorder: false,
  navigationVersionOneSelectedBorderWidth: '1',
  navigationVersionOneSelectedBorderColor: '#1E2B47',
  navigationVersionOneSelectedBorderDirection: 'all',
  navigationVersionOneSelectedIconColor: '#fff',

  // tab versionTWO GENERAL
  navigationVersionTwoGeneralIconColor: '#1E2B47',
  navigationVersionTwoGeneralBGColor: '#F7FAFF',
  navigationVersionTwoGeneralHasBorder: true,
  navigationVersionTwoGeneralBorderWidth: '1',
  navigationVersionTwoGeneralBorderColor: '#1E2B47',
  navigationVersionTwoGeneralBorderDirection: 'all',
  navigationVersionTwoGeneralSearchBGColor: '#2d5291',
  navigationVersionTwoGeneralSearchTextColor: '#2d5291',
  navigationVersionTwoGeneralSearchHasBorder: true,
  navigationVersionTwoGeneralSearchBorderColor: '#2d5291',
  navigationVersionTwoGeneralSearchBorderWidth: '1',
  navigationVersionTwoGeneralSearchBorderDirection: 'all',
  navigationVersionTwoGeneralSearchCornerRadiusTopLeft: '1',
  navigationVersionTwoGeneralSearchCornerRadiusTopRight: '1',
  navigationVersionTwoGeneralSearchCornerRadiusBottomRight: '1',
  navigationVersionTwoGeneralSearchCornerRadiusBottomLeft: '1',
  // tab versionTWO SELECTED
  navigationVersionTwoSelectedIconColor: '#fff',
  navigationVersionTwoSelectedTextColor: '#fff',
  navigationVersionTwoSelectedBGColor: '#1E2B47',
  navigationVersionTwoSelectedHasBorder: false,
  navigationVersionTwoSelectedBorderWidth: '1',
  navigationVersionTwoSelectedBorderColor: '#1E2B47',
  navigationVersionTwoSelectedBorderDirection: 'all',
  navigationVersionTwoSelectedCornerRadiusTopLeft: '20',
  navigationVersionTwoSelectedCornerRadiusTopRight: '20',
  navigationVersionTwoSelectedCornerRadiusBottomRight: '20',
  navigationVersionTwoSelectedCornerRadiusBottomLeft: '20',
  // tab versionTHREE GENERAL
  navigationVersionThreeGeneralIconColor: '#1E2B47',
  navigationVersionThreeGeneralTextColor: '#1E2B47',
  navigationVersionThreeGeneralBGColor: '#F7FAFF',
  navigationVersionThreeGeneralHasBorder: true,
  navigationVersionThreeGeneralBorderWidth: '1',
  navigationVersionThreeGeneralBorderColor: '#1E2B47',
  navigationVersionThreeGeneralBorderDirection: 'all',
  navigationVersionThreeGeneralSearchBGColor: '#2d5291',
  navigationVersionThreeGeneralSearchTextColor: '#2d5291',
  navigationVersionThreeGeneralSearchHasBorder: true,
  navigationVersionThreeGeneralSearchBorderColor: '#2d5291',
  navigationVersionThreeGeneralSearchBorderWidth: '1',
  navigationVersionThreeGeneralSearchBorderDirection: 'all',
  navigationVersionThreeGeneralSearchCornerRadiusTopLeft: '1',
  navigationVersionThreeGeneralSearchCornerRadiusTopRight: '1',
  navigationVersionThreeGeneralSearchCornerRadiusBottomRight: '1',
  navigationVersionThreeGeneralSearchCornerRadiusBottomLeft: '1',
  // tab versionThree SELECTED
  navigationVersionThreeSelectedIconColor: '#2D5291',
  navigationVersionThreeSelectedTextColor: '#2D5291',
  navigationVersionThreeSelectedBGColor: '#2D5291',
  navigationVersionThreeSelectedSelectorCornerRadiusTopLeft: '10',
  navigationVersionThreeSelectedSelectorCornerRadiusTopRight: '10',
  navigationVersionThreeSelectedSelectorCornerRadiusBottomRight: '0',
  navigationVersionThreeSelectedSelectorCornerRadiusBottomLeft: '0',

  /* History List*/

  alertErrorColor: '#E02A57',
  alertSuccessColor: '#25C196',
  alertWarningColor: '#FFB700',
  alertInfoColor: '#0076C2',
  alertLinkColor: '#80CDFF',

  amountHintColor: '#99b6ff',


  // z-index
  zIndex9: 9,
  zIndex99: 99,
  zIndex999: 999,
  zIndex9999: 9999,
  zIndex99999: 99999,
  icons: {
    globeIcon: 'e900',
    videoIcon: 'e901',
    search: 'e902',
    processingTimeNotInstant: 'e903',
    processingTimeInstant: 'e904',
    apple: 'e905',
    arrowLeft: 'e906',
    arrowRight: 'e907',
    arrowDown: 'e908',
    arrowUp: 'e909',
    close: 'e90a',
    starFull: 'e90b',
    starBorder: 'e90c',
    warning: 'e90d',
    info: 'e90e',
    error: 'e90f',
    success: 'e910',
    download: 'e911'
  },
  common: {
    defaultMode: 'dark',
    fontFamily: 'roboto',
    shape: 'rectangleRadiusMd',
    notificationTypes: {
      id: 1,
      label: 'Information',
      value: 'Information'
    },
    showNotification: false,
    notificationHasBorderInformation: false,
    notificationBorderDirectionInformation: 'all',
    notificationBorderWidthInformation: '1',
    notificationCornerRadiusInformation: {
      TopLeft: '10',
      TopRight: '10',
      BottomRight: '10',
      BottomLeft: '10'
    },
    notificationPositionInformation: 'bottomCenter',
    notificationHasBorderError: false,
    notificationBorderDirectionError: 'all',
    notificationBorderWidthError: '2',
    notificationCornerRadiusError: {
      TopLeft: '5',
      TopRight: '5',
      BottomRight: '5',
      BottomLeft: '5'
    },
    notificationPositionError: 'topCenter',
    navigationTabVersion: {
      id: 3,
      label: 'Version 3',
      value: 'versionThree'
    },
    navigationVersionThreeGeneralHasBorder: true,
    navigationVersionThreeGeneralBorderWidth: '1',
    navigationVersionThreeGeneralBorderDirection: 'all',
    navigationVersionThreeGeneralHasIcon: true,
    navigationVersionThreeGeneralCornerRadius: {
      TopLeft: '24',
      TopRight: '24',
      BottomRight: '24',
      BottomLeft: '24'
    },
    navigationVersionThreeSelectedHasBorder: false,
    navigationVersionThreeSelectedBorderWidth: '1',
    navigationVersionThreeSelectedBorderDirection: 'all',
    groupHeaderHasBorder: false,
    groupHeaderBorderDirection: 'all',
    groupHeaderBorderWidth: '1',
    groupHeaderCornerRadius: {
      TopLeft: '16',
      TopRight: '16',
      BottomRight: '16',
      BottomLeft: '16'
    },
    cardCornerRadius: {
      TopLeft: '24',
      TopRight: '24',
      BottomRight: '24',
      BottomLeft: '24'
    },
    cardHasShadow: false,
    cardHasBorder: false,
    cardBorderDirection: 'all',
    cardBorderWidth: '1',
    cardLogoContainerCornerRadius: {
      TopLeft: '20',
      TopRight: '20',
      BottomRight: '20',
      BottomLeft: '20'
    },
    cardHoverMotion: 'nothing',
    inputHasBorder: false,
    inputBorderDirection: 'all',
    inputHasLabel: true,
    inputCornerRadius: {
      TopLeft: '18',
      TopRight: '18',
      BottomRight: '18',
      BottomLeft: '18'
    },
    mainButtonHasBorder: false,
    mainButtonBorderDirection: 'all',
    mainButtonBorderWidth: '0',
    mainButtonCornerRadius: {
      TopLeft: '18',
      TopRight: '18',
      BottomRight: '18',
      BottomLeft: '18'
    },
    favoriteButtonHasBorder: true,
    favoriteButtonBorderDirection: 'all',
    favoriteButtonBorderWidth: '1',
    favoriteButtonCornerRadius: {
      TopLeft: '15',
      TopRight: '15',
      BottomRight: '15',
      BottomLeft: '15'
    }
  },
  light: {
    brandColor: '#ffffff',
    coreColor: '#000000',
    generalBackgroundColor: '#ffffff',
    generalIconsColor: '#c5c5c5',
    notificationIconColorInformation: '#007fc2',
    notificationTitleColorInformation: '#5c5c5c',
    notificationBackgroundColorInformation: '#f1f1f1',
    notificationBorderColorInformation: '#007fc2',
    notificationBorderColorError: '#e02a57',
    notificationIconColorError: '#e02a57',
    notificationTitleColorError: '#5c5c5c',
    notificationBackgroundColorError: '#f1f1f1',
    navigationVersionThreeGeneralIconColor: '#c5c5c5',
    navigationVersionThreeGeneralTextColor: '#5c5c5c',
    navigationVersionThreeGeneralBGColor: '#f9f9f9',
    navigationVersionThreeGeneralBorderColor: '#e2e2e2',
    navigationVersionThreeGeneralSearchBGColor: '#f1f1f1',
    navigationVersionThreeGeneralSearchTextColor: '#5c5c5c',
    navigationVersionThreeGeneralSearchBorderColor: '#e2e2e2',
    navigationVersionThreeSelectedIconColor: '#ffffff',
    navigationVersionThreeSelectedTextColor: '#ffffff',
    navigationVersionThreeSelectedBGColor: '#ffffff',
    groupHeaderTitleColor: '#5c5c5c',
    groupHeaderBackgroundColor: '#f1f1f1',
    groupHeaderBorderColor: '#f1f1f1',
    generalCardBackgroundColor: '#f1f1f1',
    cardTextTitleColor: '#8b8b8b',
    cardTextValueColor: '#2b2b2b',
    cardNewLabelBackgroundColor: '#25c196',
    cardNewLabelTextColor: '#ffffff',
    cardUnderMaintenanceLabelBackgroundColor: '#e02a57',
    cardUnderMaintenanceLabelTextColor: '#ffffff',
    cardBonusLabelBackgroundColor: '#25c196',
    cardBonusLabelTextColor: '#ffffff',
    cardShadowColor: '#c5c5c5',
    cardHoverButtonBgColor: '#ffffff',
    inputBackgroundColor: '#f1f1f1',
    inputTextColor: '#2b2b2b',
    inputBorderColor: '#f1f1f1',
    inputLabelColor: '#2b2b2b',
    mainButtonBackgroundColor: '#ffffff',
    mainButtonTextColor: '#ffffff',
    mainButtonBorderColor: '#ffffff',
    favoriteButtonBackgroundColor: '#f1f1f1',
    favoriteButtonTextColor: '#5c5c5c',
    textButtonBackgroundColor: '#ffffff',
    textTitleColor: '#2b2b2b',
    textContentColor: '#5c5c5c',
    palette: {
      BLACK: '#000000',
      DARKEST: '#212121',
      DARK: '#2b2b2b',
      MEDIUM: '#5c5c5c',
      REGULAR: '#8b8b8b',
      SEMI_LIGHT: '#c5c5c5',
      LIGHT: '#e2e2e2',
      ULTRA_LIGHT: '#f1f1f1',
      LIGHTEST: '#f9f9f9',
      WHITE: '#ffffff'
    },
    notificationTitleColorSuccess: '#5c5c5c',
    notificationBackgroundColorSuccess: '#f1f1f1'
  },
  dark: {
    brandColor: '#ffffff',
    coreColor: '#000000',
    generalBackgroundColor: '#0e0e0e',
    generalIconsColor: '#757575',
    notificationIconColorInformation: '#007fc2',
    notificationTitleColorInformation: '#c2c2c2',
    notificationBackgroundColorInformation: '#1f1f1f',
    notificationBorderColorInformation: '#007fc2',
    notificationBorderColorError: '#e02a57',
    notificationIconColorError: '#e02a57',
    notificationTitleColorError: '#c2c2c2',
    notificationBackgroundColorError: '#1f1f1f',
    navigationVersionThreeGeneralIconColor: '#757575',
    navigationVersionThreeGeneralTextColor: '#c2c2c2',
    navigationVersionThreeGeneralBGColor: '#111111',
    navigationVersionThreeGeneralBorderColor: '#404040',
    navigationVersionThreeGeneralSearchBGColor: '#1f1f1f',
    navigationVersionThreeGeneralSearchTextColor: '#c2c2c2',
    navigationVersionThreeGeneralSearchBorderColor: '#404040',
    navigationVersionThreeSelectedIconColor: '#ffffff',
    navigationVersionThreeSelectedTextColor: '#ffffff',
    navigationVersionThreeSelectedBGColor: '#ffffff',
    groupHeaderTitleColor: '#c2c2c2',
    groupHeaderBackgroundColor: '#1f1f1f',
    groupHeaderBorderColor: '#1f1f1f',
    generalCardBackgroundColor: '#1f1f1f',
    cardTextTitleColor: '#757575',
    cardTextValueColor: '#ffffff',
    cardNewLabelBackgroundColor: '#25c196',
    cardNewLabelTextColor: '#0e0e0e',
    cardUnderMaintenanceLabelBackgroundColor: '#e02a57',
    cardUnderMaintenanceLabelTextColor: '#0e0e0e',
    cardBonusLabelBackgroundColor: '#25c196',
    cardBonusLabelTextColor: '#0e0e0e',
    cardHoverButtonBgColor: '#ffffff',
    inputBackgroundColor: '#1f1f1f',
    inputTextColor: '#ffffff',
    inputBorderColor: '#1f1f1f',
    inputLabelColor: '#ffffff',
    mainButtonBackgroundColor: '#ffffff',
    mainButtonTextColor: '#ffffff',
    mainButtonBorderColor: '#ffffff',
    favoriteButtonBackgroundColor: '#1f1f1f',
    favoriteButtonTextColor: '#c2c2c2',
    textButtonBackgroundColor: '#ffffff',
    textTitleColor: '#ffffff',
    textContentColor: '#c2c2c2',
    palette: {
      BLACK: '#000000',
      DARKEST: '#0e0e0e',
      DARK: '#111111',
      MEDIUM: '#1f1f1f',
      REGULAR: '#2e2e2e',
      SEMI_LIGHT: '#404040',
      LIGHT: '#757575',
      ULTRA_LIGHT: '#c2c2c2',
      LIGHTEST: '#eaeaea',
      WHITE: '#ffffff'
    },
    notificationTitleColorSuccess: '#c2c2c2',
    notificationBackgroundColorSuccess: '#1f1f1f'
  }
};
