import styled, {css} from 'styled-components';
import {
  flexContainer,
  flexContainerWithGap,
  fontStyle,
  getBorder,
  getBorderRadius,
  getBoxShadow
} from '../../../../helpers/styled';

/**
 * Styled Group Name and Line Message
 * */
const StyledGroupName = styled.h2`
  height: 24px;
  ${flexContainerWithGap()};
  margin-bottom: 8px;
  ${fontStyle()};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  line-height: 24px;
  color: ${({theme}) => theme.groupHeaderTitleColor};
  text-transform: capitalize;
  white-space: nowrap;
`;

const StyledLine = styled.span`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.border.borderSecondary};
`;

/**
 * Styled Payment System List Wrapper
 */
const StyledPsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 12px), 1fr));
  gap: 12px;
`;

const StyledPsWrapperMobile = styled(StyledPsWrapper)`
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-template-rows: 78px;
  gap: 8px;
`;


/**
 * Styled Payment System List Items
 */
const StyledPaymentItem = styled.figure`
  position: relative;
  ${flexContainer({justifyContent: 'space-between', gap: '8px'})};
  padding: 8px 12px 8px 8px;
  border: ${(props) => getBorder(props, 'card')}; // Automatically handles border width, color, and style
  border-radius: ${(props) => getBorderRadius(props, 'card')};
  box-shadow: ${({theme}) => getBoxShadow(theme)};
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
  transition: 0.4s; /* 400ms is now 0.4s for brevity */
  cursor: pointer;
  
  ${({fromNestedPage}) => fromNestedPage && css`
    background-color: ${({theme}) => theme.surface.surfaceSecondary};
    
    &:hover {
      padding-left: 16px;
    }
  `}
`;

/**
 * Payment Item Mobile Logo Container
 */
const StyledPaymentItemLogoWrapper = styled.div`
  position: relative;
  ${flexContainer()};
  width: 80px;
  height: 62px;
  background-color: ${({theme}) => theme.generalColorNeutralMinimum};
  border-radius: ${(props) => getBorderRadius(props, 'cardLogoContainer')};

  img {
    width: 100%;
    height: 100%;
  }
`;

/**
 * Payment New / Under Maintenance Label
 */
const StyledPaymentHint = styled.span`
  height: auto;
  padding: 2px 4px;
  background-color: ${({theme}) => theme.cardNewLabelBackgroundColor};
  color: ${({theme}) => theme.cardNewLabelTextColor};
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  line-height: 14px;
  border-radius: ${(props) => getBorderRadius(props, 'hint')};
  white-space: nowrap;
  
  ${(props) => props.unavailable && css`
    background-color: ${() => props.theme.cardUnderMaintenanceLabelBackgroundColor};
    color: ${() => props.theme.cardUnderMaintenanceLabelTextColor};
  `};

  ${(props) => props.isNew && css`
    background-color: ${() => props.theme.cardNewLabelBackgroundColor};
    color: ${() => props.theme.cardNewLabelTextColor};
  `};

  ${(props) => props.amountHint && css`
    background-color: ${() => props.theme.cardBonusLabelBackgroundColor};
    color: ${({theme}) => theme.text.textInvert};
  `};
`;

/**
 * Payment Item List Container
 */
const StyledPaymentItemFigcaptionList = styled.ul`
  height: 16px;
  ${flexContainer({
    justifyContent: 'flex-start',
    gap: '8px'
  })};

  ${(props) => props.isDropdownOption && css`
    gap: 4px
  `};
`;

/**
 * Styled Payment Item Label Colors
 */
const StyledPaymentItemLabel = styled.h3`
  ${flexContainerWithGap('2px')};
  justify-content: space-between;
  color: ${({theme}) => theme.cardTextTitleColor};
  font-weight: ${({theme}) => theme.typographyFontWeightRegular};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  line-height: 1rem; /* Using rem for consistent sizing */
`;

const StyledSeparator = styled.span`
  margin: -2px 0 0 2px;
  color: ${({theme}) => theme.border.borderSecondary};
`;

/**
 * Styled Payment Item Value Colors
 */
const StyledPaymentItemValue = styled.span`
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  color: ${({theme}) => theme.cardTextValueColor};
`;

const StyledFavoriteButton = styled.button`
  ${flexContainer()};
  border: 0;
  background-color: transparent;

  ${(props) => props.isFavorite && css`
    svg path {
      fill: ${() => props.theme.warningColor};
    }
  `};
`;

const StyledDropdownIconContainer = styled.div`
  width: 20px;
  height: 20px;
  ${flexContainer()};
  
  svg {
    transition: 400ms;
  }
  
  svg path {
    fill: ${({theme}) => theme.generalIconsColor};
  }
  
  ${(props) => props.isOpen && css`
    svg {
      transform: rotate(-180deg);
    }
  `};
`;

export default StyledPaymentItem;
export {
  StyledPsWrapper,
  StyledGroupName,
  StyledLine,
  StyledPaymentHint,
  StyledPaymentItemLogoWrapper,
  StyledPaymentItemFigcaptionList,
  StyledPaymentItemLabel,
  StyledPaymentItemValue,
  StyledPsWrapperMobile,
  StyledFavoriteButton,
  StyledDropdownIconContainer,
  StyledSeparator
};
