import instance from './apiService';
import {
  BONUS_CANCELLATION_ERROR_CODE, HTTP_METHODS, OPERATION_TYPE_BY_NUMBER, PAYMENTS_IDS, REDIRECT_TO_AML_ERROR_CODE, URL_PARTS
} from '../constants/common';
import {
  flattenObject, isIOSWebView, isMobileApp, isMobNativeApp, updateTransactionsData
} from '../helpers/helperFunction';
import {getSitePaymentSystemControls} from './getSitePaymentSystemControls';
import {merchantUserMultistepControls} from './merchantUserMultistepControls';

/**
 * @param paymentSystemId
 */
const isSpecialPaymentSystem = (paymentSystemId) => [
  PAYMENTS_IDS.APPLE_PAY_ID, PAYMENTS_IDS.PAYPAL_ID, PAYMENTS_IDS.GOOGLE_PAY_ID, ...PAYMENTS_IDS.SAFETY_PAY_IDS
].includes(paymentSystemId);

/**
 * @param w1
 * @param redirectUrl
 * @param hasRedirectUrl
 */
const handleRedirect = (w1, redirectUrl, hasRedirectUrl) => {
  if (hasRedirectUrl && redirectUrl && !isMobileApp() && !isIOSWebView()) {
    w1.location.href = redirectUrl;
  } else if (hasRedirectUrl && redirectUrl) {
    if (isIOSWebView()) {
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        w1 = window.open(redirectUrl, '_blank');
        if (!w1 && !isMobNativeApp()) {
          // blocked pop-up case
          window.location.href = redirectUrl;
        }
      }, 100);
    } else {
      // eslint-disable-next-line no-param-reassign
      w1 = window.open(redirectUrl, '_blank');
    }
  }
};

/**
 * @param url
 * @param token
 * @param siteId
 * @param userId
 * @param bonusId
 * @param language
 * @param currency
 * @param operationType
 * @param payloadValues
 * @param minAmountValue
 * @param gaMeasurementId
 * @param paymentSystemId
 * @param cancelActiveBonus
 * @param analyticParameter
 * @param platformPaymentSystemId
 */
export const createRequestBody = ({
  url,
  token,
  siteId,
  userId,
  bonusId,
  language,
  currency,
  operationType,
  payloadValues,
  minAmountValue,
  gaMeasurementId,
  paymentSystemId,
  cancelActiveBonus,
  analyticParameter,
  platformPaymentSystemId
}) => {
  const flattenedData = flattenObject(payloadValues);
  const stringifyValues = JSON.stringify(flattenedData);
  const amountValues = payloadValues?.amount ? JSON.parse(payloadValues.amount)?.value ?? payloadValues.amount : null;

  const isAccountVerifyApi = url.includes(URL_PARTS.SET_MERCHANT_USER_BANK_ACCOUNT);

  return isAccountVerifyApi
    ? {
      siteId,
      paymentSystemId,
      userId: userId.toString(),
      bankAccountNumber: payloadValues.accountnumber,
      countryISO: currency,
      paymentMethodType: operationType,
      proofImageInfo: payloadValues.proofImageInfo
    }
    : {
      userId: userId.toString(),
      paymentSystemId,
      platformPsId: platformPaymentSystemId,
      siteId,
      amount: paymentSystemId === PAYMENTS_IDS.DEPOZITRON
        || paymentSystemId === PAYMENTS_IDS.SBON_VOUCHER
        || paymentSystemId === PAYMENTS_IDS.CASH_TO_GO
        ? localStorage.getItem('amountValue') || minAmountValue
        : amountValues || minAmountValue,
      currencyId: currency,
      languageId: language,
      transactionType: operationType.toString(),
      transactionData: stringifyValues,
      paymentToken: token,
      bonusId: bonusId ? String(bonusId) : null,
      gaMeasurementId,
      analyticParameter,
      ...(operationType === 2 ? {cancelActiveBonus} : {})
    };
};

/**
 * @param hasIframe
 * @param hasRedirectUrl
 * @param isTransactionCreateApi
 */
export const openRedirectWindow = ({hasIframe, hasRedirectUrl, isTransactionCreateApi}) => {
  if (hasRedirectUrl && !hasIframe && isTransactionCreateApi && !isMobileApp() && !isIOSWebView()) {
    return window.open('https://pyg.letspayments.com/finalpage?r=3', '_blank');
  }
  return '';
};

/**
 * @param url
 * @param siteId
 * @param operationType
 * @param dynamicUrlProps
 * @param paymentSystemId
 * @param setPaymentControlsData
 */
export const handleDynamicParamsRequest = async(url, siteId, operationType, dynamicUrlProps, paymentSystemId, setPaymentControlsData) => {
  const constructedUrl = `${url}/${siteId}/${paymentSystemId}/${operationType}/${Number(dynamicUrlProps.step)}`;
  if (dynamicUrlProps.httpMethodType.toUpperCase() === HTTP_METHODS.GET) {
    const response = await instance.get(constructedUrl, {
      param: {
        siteId, paymentSystemId, operationType, step: dynamicUrlProps.step
      }
    });
    setPaymentControlsData(response.data);
  }
};

/**
 * @param w1
 * @param siteId
 * @param userId
 * @param response
 * @param language
 * @param currency
 * @param isMultiStep
 * @param alertContext
 * @param secondaryUrl
 * @param amountValues
 * @param operationType
 * @param hasRedirectUrl
 * @param minAmountValue
 * @param paymentSystemId
 * @param setPaymentSources
 * @param templateTranslation
 * @param setPaymentControlsData
 * @param transactionDataSetters
 * @param setConektaTransactionData
 * @param setPaymentGeneratedCodeInfo
 * @param resetFormHandler
 * @param url
 */
export const handleResponse = ({
  url,
  w1,
  siteId,
  userId,
  response,
  language,
  currency,
  isMultiStep,
  alertContext,
  secondaryUrl,
  amountValues,
  operationType,
  hasRedirectUrl,
  minAmountValue,
  paymentSystemId,
  setPaymentSources,
  templateTranslation,
  setPaymentControlsData,
  transactionDataSetters,
  setConektaTransactionData,
  setPaymentGeneratedCodeInfo,
  resetFormHandler
}) => {
  const isAccountVerifyApi = url.includes(URL_PARTS.SET_MERCHANT_USER_BANK_ACCOUNT);

  /**
   * Handles the response from the transaction creation API call to display payment source data.
   *
   * If the API response includes `paymentSources` and `!secondaryUrl` is false, the function updates
   * the payment sources using `setPaymentSources`. In this case, the control form is replaced with
   * the provided data (e.g., barcode, QR code, or other payment-related information).
   *
   * The `!secondaryUrl` condition ensures that the data is not displayed on the right side of the UI
   * but directly replaces the control form, maintaining a clear and consistent flow for the user.
   *
   * This approach is particularly useful for payment flows where users need to interact with
   * visual elements such as barcodes or QR codes instead of filling out additional forms.
   *
   * This flow is commonly used for payment methods like BITCOIN, where the user needs to interact
   * with visual data such as a QR code or barcode to complete the transaction. Instead of requiring
   * additional input through a form, the payment information is displayed directly, streamlining the process.
   *
   * @param {Object} response - The response object from the transaction creation API call.
   * @param {Array} response.data.paymentSources - The array of payment sources returned by the API.
   * @param {boolean} secondaryUrl - A flag indicating if a secondary URL flow is enabled.
   * @param {Function} setPaymentSources - Callback function to update and display the payment sources.
   *
   * @example
   * // When payment sources are provided in the response:
   * if (response.data?.paymentSources && !secondaryUrl) {
   *   setPaymentSources(response.data.paymentSources);
   * }
   *
   * // Example scenario:
   * // - Replace the control form with a QR code or barcode from the paymentSources data.
   * // - Avoid displaying the payment source data on the right side when `secondaryUrl` is false.
   */
  if (response.data?.paymentSources && !secondaryUrl) {
    setPaymentSources(response.data.paymentSources);
  }

  /**
   * Handles the response behavior based on the presence of `secondaryUrl` or `redirectUrl` in the API response.
   *
   * - **When `secondaryUrl` is true:** The payment source data (e.g., barcodes, QR codes, or other payment information)
   *   is displayed on the right side of the UI using `setPaymentGeneratedCodeInfo`. This allows users to view or interact
   *   with the payment data while keeping the form or main content intact.
   *
   * - **When `secondaryUrl` is false and a `redirectUrl` is present:** The redirect URL from the API response is opened
   *   in a new browser tab or window using `handleRedirect`. This is particularly useful for flows where the user
   *   is redirected to an external page (e.g., a payment provider's site) to complete the transaction.
   *
   * This flow is commonly used for payment methods like OKTO CASH, where the user needs to interact
   * with visual data such as a QR code or barcode to complete the transaction.
   *
   * @param {Object} response - The response object from the transaction creation API call.
   * @param {Array} [response.data.paymentSources] - The array of payment sources returned by the API (used when `secondaryUrl` is true).
   * @param {string} [response.data.redirectUrl] - The URL to redirect the user to (used when `secondaryUrl` is false).
   * @param {boolean} secondaryUrl - A flag indicating whether to display the payment source data on the right side.
   * @param {Function} setPaymentGeneratedCodeInfo - Callback function to display payment sources on the right side.
   * @param {Function} handleRedirect - Function to handle redirection to an external URL.
   * @param {Window|null} w1 - A reference to the opened window (used for redirection).
   * @param {boolean} hasRedirectUrl - Flag indicating whether the redirect URL is allowed.
   *
   * @example
   * // When secondaryUrl is true, show payment sources on the right side:
   * if (secondaryUrl) {
   *   setPaymentGeneratedCodeInfo(response.data?.paymentSources);
   * } else {
   *   // When redirectUrl is present, open it in a new tab or window:
   *   const redirectUrl = response?.data?.redirectUrl;
   *   if (redirectUrl) handleRedirect(w1, redirectUrl, hasRedirectUrl);
   * }
   *
   * // Example scenarios:
   * // - SecondaryUrl is true: Payment data like a QR code is displayed on the right side.
   * // - SecondaryUrl is false: A redirectUrl is opened in a new tab for the user to complete the transaction externally.
   */
  if (secondaryUrl) {
    setPaymentGeneratedCodeInfo(response.data?.paymentSources);
  } else {
    const redirectUrl = response?.data?.redirectUrl;
    if (redirectUrl) handleRedirect(w1, redirectUrl, hasRedirectUrl);
  }


  /**
   * Fetches and sets payment system controls dynamically based on the provided step ID and payment system specifics.
   *
   * - **For special payment systems:** Calls `getSitePaymentSystemControls` to dynamically fetch and update the payment
   *   controls based on the specific step ID. The step ID is determined from the `controls` property of the API response
   *   or other configurations. This allows the application to handle unique flows for specific payment systems.
   *
   * - **General Behavior:** Ensures the controls are updated dynamically to reflect the step-by-step flow required by
   *   certain payment systems. This is particularly useful for complex payment systems with specialized control requirements.
   *
   * @param {Function} getSitePaymentSystemControls - Function to fetch and set the payment controls dynamically.
   * @param {Function} setPaymentControlsData - Callback to update the payment controls data.
   * @param {number|string} siteId - The ID of the site associated with the payment system.
   * @param {number|string} paymentSystemId - The ID of the payment system item being processed.
   * @param {string} operationType - The type of operation being performed (e.g., withdrawal or deposit).
   * @param {number} stepId - The step ID retrieved from the controls property to define the specific control step.
   * @param {Function} callback - A callback function to handle post-control updates or actions.
   * @param {Object} alertContext - Function for handling alerts, including success and error messages.
   *
   * @example
   * // When a special payment system is detected, fetch controls dynamically by step ID:
   * if (isSpecialPaymentSystem(paymentSystemId)) {
   *   updateTransactionsData(paymentSystemId, response, transactionDataSetters, {
   *     amount: amountValues || minAmountValue,
   *     currencyId: currency
   *   });
   *   getSitePaymentSystemControls(
   *     setPaymentControlsData,
   *     siteId,
   *     paymentSystemId,
   *     operationType,
   *     2, // Example step ID
   *     () => {},
   *     alertContext
   *   );
   * }
   *
   * // Example scenario:
   * // - A payment system has specific controls defined for step ID `2`.
   * // - `getSitePaymentSystemControls` retrieves the controls configuration dynamically.
   * // - The front-end updates the UI to reflect the retrieved controls, allowing for a smooth step-by-step flow.
   */
  if (isSpecialPaymentSystem(paymentSystemId)) {
    updateTransactionsData(paymentSystemId, response, transactionDataSetters, {
      amount: amountValues || minAmountValue,
      currencyId: currency
    });
    getSitePaymentSystemControls(setPaymentControlsData, siteId, paymentSystemId, operationType, 2, () => {}, alertContext);
  } else if (paymentSystemId === PAYMENTS_IDS.CONEKTA_CREDIT_CARD_ID && typeof setConektaTransactionData === 'function') {
    setConektaTransactionData(JSON.parse(response?.data?.customScript));
  }

  /**
   * Handles dynamic control steps for multi-step payment flows.
   *
   * * - **When `isMultiStep` is true:** Initiates a call to `merchantUserMultistepControls` to manage dynamic control steps
   *  *   for multi-step payment processes. In this scenario, the payment control steps are dynamically determined and
   *  *   managed by the back-end. The function fetches the updated control step configuration from the server and updates
   *  *   the front-end accordingly.
   *
   * @param {boolean} isMultiStep - Indicates whether the payment process involves multiple steps.
   * @param {Function} merchantUserMultistepControls - Function to handle multi-step control steps dynamically.
   * @param {Function} setPaymentControlsData - Callback to update the payment controls data.
   * @param {number|string} siteId - The ID of the site associated with the payment system.
   * @param {number|string} paymentSystemId - The ID of the payment system item.
   * @param {string} operationType - The type of operation being performed (e.g., withdrawal or deposit).
   * @param {number|string} userId - The ID of the user performing the transaction.
   * @param {string} currency - The currency associated with the transaction.
   * @param {string} language - The language for localization and translations.
   * @param {Function} alertContext - Function for handling alerts, including success and error messages.
   * @param {Object} templateTranslation - Object containing localized strings for UI messages.
   * @param {string} templateTranslation.successtransaction - Success message for a completed transaction.
   *
   * @example
   * // When isMultiStep is true, dynamically handle control steps:
   * if (isMultiStep) {
   *   merchantUserMultistepControls(
   *     setPaymentControlsData,
   *     siteId,
   *     paymentSystemId,
   *     operationType,
   *     userId,
   *     currency,
   *     language,
   *     () => {},
   *     alertContext
   *   );
   * }
   */
  if (isMultiStep) {
    merchantUserMultistepControls(
      setPaymentControlsData,
      siteId,
      paymentSystemId,
      operationType,
      userId,
      currency,
      language,
      () => {},
      alertContext
    );
  }

  /**
   *
   * - **When `operationType` is a withdrawal (`OPERATION_TYPE_BY_NUMBER.WITHDRAWAL`):** Displays a success
   *   message to the user using the `alertContext` with a translated success message provided by
   *   `templateTranslation.successtransaction`.
   *
   *   When operationType is withdrawal, display a success message:
   * */
  if (operationType === OPERATION_TYPE_BY_NUMBER.WITHDRAWAL && !isAccountVerifyApi) {
    alertContext?.success(templateTranslation?.successtransaction);
    if (typeof resetFormHandler === 'function') resetFormHandler();
  }
};

/**
 * @param w1
 * @param error
 * @param hasIframe
 * @param alertContext
 * @param operationType
 * @param hasRedirectUrl
 * @param isTransactionCreateApi
 * @param setAMLRedirectionMessage
 * @returns {boolean}
 */
export const handleError = ({
  w1,
  error,
  hasIframe,
  alertContext,
  operationType,
  hasRedirectUrl,
  isTransactionCreateApi,
  setAMLRedirectionMessage
// eslint-disable-next-line consistent-return
}) => {
  if (operationType === OPERATION_TYPE_BY_NUMBER.WITHDRAWAL) {
    if (error?.response?.data?.code === BONUS_CANCELLATION_ERROR_CODE) {
      throw new Error(JSON.stringify({result: true}));
    }
    if (error?.response?.data?.code === REDIRECT_TO_AML_ERROR_CODE) {
      setAMLRedirectionMessage(error.response.data.message);
      return false;
    }
  }
  alertContext?.error(error?.response?.data);

  if (hasRedirectUrl && !hasIframe && isTransactionCreateApi && !isMobileApp() && !isIOSWebView()) {
    w1.close();
  }
};
