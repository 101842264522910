import React, {
  useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {SelectedPaymentDetailsContext} from '../contexts/selectedPaymentDetailsContext';
import {PaymentFormCallbacksContext} from '../contexts/paymentFormCallbacksContext';
import {CashierDetailsContext} from '../contexts/cashierDetailsContext';
import {HISTORY_TAB} from '../constants/common';
import {SearchIcon} from '../assets/icons';

// styled
import StyledInputWrapper, {StyledInput} from '../styledComponents/input/input.styled';
import {StyledSearchInputWrapper} from '../components/search/search.styled';

export const templateTwoHistoryHOC = (History) => function TemplateTwoHistoryHOC({alertContext}) {
  TemplateTwoHistoryHOC.propTypes = {
    alertContext: PropTypes.object.isRequired
  };

  const {t} = useTranslation();

  const [paidData, setPaidData] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const [amountToFilter, setAmountToFilter] = useState(null);

  const {siteId, userId} = useContext(CashierDetailsContext);
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {setIsLoading, getHistoryByTabForTemplateTwo} = useContext(PaymentFormCallbacksContext);

  const onChangeHandler = (e) => {
    const amountFormated = Number(e.target.value);
    if (amountFormated) {
      setAmountToFilter(amountFormated);
    } else {
      setAmountToFilter(null);
    }
  };

  const [paidDataFiltered, expiredDataFiltered] = useMemo(() => {
    if (amountToFilter) {
      return [paidData.filter(({amount}) => amount === amountToFilter), expiredData.filter(({amount}) => amount === amountToFilter)];
    }

    return [paidData, expiredData];
  }, [paidData, expiredData, amountToFilter]);

  useEffect(() => {
    if (typeof getHistoryByTabForTemplateTwo === 'function') {
      getHistoryByTabForTemplateTwo(
        setPaidData,
        paymentSystemId,
        siteId,
        userId,
        HISTORY_TAB.PAID,
        setIsLoading,
        alertContext
      );
      getHistoryByTabForTemplateTwo(
        setExpiredData,
        paymentSystemId,
        siteId,
        userId,
        HISTORY_TAB.EXPIRED,
        setIsLoading,
        alertContext
      );
    }
  }, [alertContext, setIsLoading, getHistoryByTabForTemplateTwo]);

  return (
    <>
      <StyledSearchInputWrapper>
        <StyledInputWrapper>
          <SearchIcon />
          <StyledInput
            type="text"
            className="search-input"
            placeholder={t(('searchByAmount')?.toLowerCase())}
            onChange={onChangeHandler}
            value={amountToFilter ? String(amountToFilter) : amountToFilter}
            isSearchInput
          />
        </StyledInputWrapper>
      </StyledSearchInputWrapper>
      <History paidData={paidDataFiltered} expiredData={expiredDataFiltered} />
    </>
  );
};
