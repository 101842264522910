import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {CODE_TYPE} from '../../../constants/common';
import {TempTwoHistoryBarCodeIcon, TempTwoHistoryQRCodeIcon} from '../../../assets/icons';
import {formatDate} from '../../../helpers/common';

// styled
import {
  StyledPaymentHint,
  StyledPaymentItemLabel,
  StyledPaymentItemValue
} from '../../paymentsList/templateTwo/common/paymentItemTemplateTwo.styled';
import {StyledFlexContainerDynamic} from '../../paymentBonusesList/paymentBonusesListWeb.styled';
import {StyledCardNumber} from '../../tabs/tabs.styled';
import {StyledPaymentCard} from '../../bonusPaymentItem/bonusPaymentItem.styled';

function PaymentHistorySingleItem({
  data,
  date,
  amount,
  orderId,
  currencyISO,
  transactionId
}) {
  const {t} = useTranslation();

  const CodeIcon = useMemo(() => {
    if (data.find((item) => item.dataName === CODE_TYPE.BAR_CODE)) return <TempTwoHistoryBarCodeIcon />;
    if (data.find((item) => item.dataName === CODE_TYPE.QR_CODE)) return <TempTwoHistoryQRCodeIcon />;
    return null;
  }, [data]);

  return (
    <StyledPaymentCard>
      <StyledFlexContainerDynamic
        gap="8px"
        alignItem="center"
      >
        {CodeIcon}
        <figcaption>
          <StyledCardNumber>{orderId || transactionId}</StyledCardNumber>
          <div>
            <StyledPaymentItemLabel>
              {t('date&time')}
              <StyledPaymentItemValue>
                {formatDate(date)}
              </StyledPaymentItemValue>
            </StyledPaymentItemLabel>
            <StyledPaymentHint amountHint>
              {`${amount} ${currencyISO}`}
            </StyledPaymentHint>
          </div>
        </figcaption>
      </StyledFlexContainerDynamic>
    </StyledPaymentCard>
  );
}

PaymentHistorySingleItem.propTypes = {
  orderId: PropTypes.number,
  transactionId: PropTypes.number,
  data: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currencyISO: PropTypes.string.isRequired
};

PaymentHistorySingleItem.defaultProps = {
  orderId: null,
  transactionId: null
};

export default PaymentHistorySingleItem;
