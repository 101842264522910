/**
 * All constant values that used in project
 */
import {
  AccountIcon,
  AddressIcon,
  AmexIcon,
  BankIcon,
  CardsIcon,
  DinersIcon,
  DiscoverIcon,
  DocsIconSmall,
  EmailIcon,
  JCBIcon,
  LaserIcon,
  MaestroIcon,
  MasterIcon,
  PhoneIcon,
  SoloIcon,
  SwitchIcon,
  VisaIcon,
  DefaultCardIcon,
  WalletIcon
} from '../assets/icons';
import {PAYMENT_CONTROL_TYPE} from './formControlType';

/**
 * GETTING DEVICE TYPE
 * */
export const DEVICE_TYPE = {
  WEB: 1,
  MOBILE: 2
};

/**
 * GETTING TEMPLATE TYPE
 * */
export const TEMPLATE_TYPE = {
  TEMPLATE_ONE: 1,
  TEMPLATE_TWO: 2
};

export const OPERATION_TYPE_BY_NUMBER = {
  DEPOSIT: 1,
  WITHDRAWAL: 2
};

export const CONTROL_STATE = {
  SUCCESS: 1,
  FAIL: 2,
  IN_PROCESS: 3,
  INFO: 4
};

export const CODE_TYPE = {
  BAR_CODE: 'BarCode',
  QR_CODE: 'QrCode',
  QR_GENERATE: 'QR',
  DESCRIPTION: 'Description',
  AMOUNT: 'Amount'
};

export const HISTORY_TAB = {
  NEW: 2,
  PAID: 3,
  EXPIRED: 4
};

export const MOBILE_SIZE = '800px';
export const TABLET_SIZE = '1220px';

export const HOVER_MOTIONS_LIST = {
  NOTHING: 'nothing',
  ZOOM: 'zoom',
  BUTTON: 'button'
};

export const buttonControls = [
  PAYMENT_CONTROL_TYPE.BUTTON_WIDTH_URL,
  PAYMENT_CONTROL_TYPE.BUTTON_WITH_MULTI_URL
];

export const DEFAULT_LANGUAGE = 'default';

export const PAYPAL_CLIENT_ID = 'Af2G3tXRi_Ts8vZ1xzRgm_zeu0jCnvswmPYe_1VpooWHG91VmZ6d8d_cGb5slWoddC3WLz_Ne00ahx0O';
export const INTENT = 'authorize';

export const BASE_URL = 'https://pygcashierapi.tst-digi.com/api/';

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8'
};

export const DROPDOWN_ICONS = {
  account: AccountIcon,
  address: AddressIcon,
  bank: BankIcon,
  card: CardsIcon,
  document: DocsIconSmall,
  email: EmailIcon,
  phone: PhoneIcon,
  wallet: WalletIcon
};

export const CARD_TYPE_ICONS = {
  0: DefaultCardIcon,
  1: VisaIcon,
  2: MasterIcon,
  3: AmexIcon,
  4: DinersIcon,
  5: DiscoverIcon,
  6: JCBIcon,
  7: LaserIcon,
  8: MaestroIcon,
  9: SoloIcon,
  10: SwitchIcon
};

export const TRANSACTION_STATUSES = {
  APPROVED: 'APPROVED',
  ERROR: 'ERROR',
  DECLINED: 'DECLINED'
};

export const BASE_REQUEST = {
  API_VERSION: 2,
  API_VERSION_MINOR: 0
};

export const TIME_UNITS = {
  HOURLY: 6,
  MINUTE: 8,
  INSTANT: 0
};

export const DEFAULT_PAYMENT_ALERTS = {
  1: {
    set: 'paymentSetAsDefaultForDeposit',
    unSet: 'paymentUnsetFromDefaultForDeposit',
    error: 'paymentDefaultRequestErrorForDeposit'
  },
  2: {
    set: 'paymentSetAsDefaultForWithdrawal',
    unSet: 'paymentUnsetFromDefaultForWithdrawal',
    error: 'paymentDefaultRequestErrorForWithdrawal'
  }
};

export const ACCOUNT_VERIFICATION_STATUSES = {
  VERIFIED: 'Verified',
  IN_REVIEW: 'In Review',
  DECLINED: 'Verification Declined'
};

export const groupNameMapping = {
  1: 'bankTransfer',
  2: 'creditAndDebitCards',
  3: 'eVoucherPrepaid',
  4: 'cash',
  5: 'crypto',
  6: 'eWallet',
  7: 'mobilePayment',
  102: 'recommended',
  103: 'favorite',
  '-2': 'recommended'
};

export const SAFETY_PAY_IDS = [
  2131,
  2179,
  2172,
  2178,
  2173,
  2175,
  2171,
  2170,
  2177,
  2174,
  2414,
  2592,
  2593,
  2594,
  2595,
  2596,
  2597,
  2598,
  2599,
  2600,
  2601,
  2602,
  2603,
  2604,
  2605,
  2606,
  2607,
  2608,
  2609,
  2610,
  2611,
  2612,
  2613,
  2709,
  2712,
  2713
];

export const AMOUNT_MESSAGE_STATUSES = {
  ERROR: 'error',
  SUCCESS: 'success',
  DEFAULT: 'default'
};

export const AML_POST_MESSAGE = {
  type: 'cw_open_declarations'
};

export const UPDATE_PROFILE_MESSAGE = {
  type: 'cw_update_profile'
};

export const PAYMENT_RIGHT_SIDE_CARDS_CALLBACK_NAMES = {
  SET_OPTIONS: 'setOptions',
  DELETE_OPTION: 'deleteOption'
};

export const ADD_NEW_OPTION_VALUE = '-1';

export const FIELD_WIDTH_BY_GROUP_SIZE = {
  1: '100%',
  2: 'calc(50% - 4px)'
};

export const EXPIRATION_DATE_FIELD_NAME = 'ExpirationDate';

export const FIELDS_CUSTOM_ERROR_NAMES = {
  INVALID_CARD_NUMBER: 'invalidCardNumberError',
  INVALID_EXPIRATION_DATE: 'invalidExpirationDateError'
};

export const FIELDS_DEFAULT_CUSTOM_ERRORS = {
  [FIELDS_CUSTOM_ERROR_NAMES.INVALID_CARD_NUMBER]: false,
  [FIELDS_CUSTOM_ERROR_NAMES.INVALID_EXPIRATION_DATE]: false
};

export const EXPIRATION_DATE_LENGTH = 5;

export const SPACE_REG_EXP = /\s+/g;

export const MONTH_MAX_DIGITS = {
  FIRST_DIGIT: 1,
  SECOND_DIGIT: 2,
  MONTH_OVERFLOW: 13
};

export const CARD_NUMBER_MAX_LENGTH = 20;
