import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/* Styled Components */
import StyledTabsContainer, {
  StyledTabsHeader,
  StyledTabButton,
  StyledTabsContent
} from './tabs.styled';
import {StyledIconWrapper} from '../../styledComponents/input/input.styled';

function Tabs({childIndexToShow, children}) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (childIndexToShow !== null) setActiveIndex(childIndexToShow);
    else setActiveIndex(0);
  }, [children, childIndexToShow]);

  // Render tabs and their corresponding content
  return (
    <StyledTabsContainer>
      <StyledTabsHeader>
        {React.Children.map(children, (child, index) => (
          <StyledTabButton
            type="button"
            key={child.props.id}
            activeTab={activeIndex === index}
            onClick={() => setActiveIndex(index)}
          >
            {child.props.icon && <StyledIconWrapper>{child.props.icon}</StyledIconWrapper>}
            {child.props.title}
          </StyledTabButton>
        ))}
      </StyledTabsHeader>
      <StyledTabsContent className="tab-content">
        {React.Children.map(children, (child, index) => (
          index === activeIndex && <div key={child.props.id}>{child.props.children}</div>
        ))}
      </StyledTabsContent>
    </StyledTabsContainer>
  );
}

Tabs.propTypes = {
  childIndexToShow: PropTypes.number,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.node, // Accepts any renderable icon component
        children: PropTypes.node.isRequired
      })
    })
  ).isRequired
};

Tabs.defaultProps = {
  childIndexToShow: null
};

export default Tabs;
