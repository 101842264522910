import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {templateTwoHistoryHOC} from '../../../../HOC/templateTwoHistoryHOC';
import PaymentHistorySingleItem from '../HistorySingleItem';

// styled
import {StyledGroupName, StyledLine} from '../../../paymentsList/templateTwo/common/paymentItemTemplateTwo.styled';
import {StyledFlexContainerDynamic} from '../../../paymentBonusesList/paymentBonusesListWeb.styled';

function HistoryMobile({paidData, expiredData}) {
  const {t} = useTranslation();

  return (
    <StyledFlexContainerDynamic
      direction="column"
      gap="16px"
    >
      {paidData.length > 0 ? (
        <section>
          <StyledGroupName>
            {t('paid')}
            <StyledLine />
          </StyledGroupName>
          <StyledFlexContainerDynamic
            direction="column"
            gap="8px"
          >
            {paidData.map((item) => <PaymentHistorySingleItem key={item.transactionId || item.orderId} {...item} />)}
          </StyledFlexContainerDynamic>
        </section>
      ) : null}
      {expiredData.length > 0 ? (
        <section>
          <StyledGroupName>
            {t('expired')}
            <StyledLine />
          </StyledGroupName>
          <StyledFlexContainerDynamic
            direction="column"
            gap="8px"
          >
            {expiredData.map((item) => <PaymentHistorySingleItem key={item.transactionId || item.orderId} {...item} />)}
          </StyledFlexContainerDynamic>
        </section>
      ) : null}
    </StyledFlexContainerDynamic>
  );
}

HistoryMobile.propTypes = {
  paidData: PropTypes.array.isRequired,
  expiredData: PropTypes.array.isRequired
};

export default templateTwoHistoryHOC(HistoryMobile);
