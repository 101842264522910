import styled from 'styled-components';
import {
  flexColumnContainer,
  flexContainer,
  fontStyle,
  getBorderRadius
} from '../../helpers/styled';

const StyledChooseBonusText = styled.p`
  ${fontStyle('regular')};
  color: ${({theme}) => theme.inputTextColor};
`;

const StyledTextButton = styled.button`
  ${fontStyle('semiBold')};
  font-size: ${(props) => props.theme.typographyFontSizeMiddle};
  line-height: 24px;
  background-color: unset;
  color: ${(props) => props.theme.textButtonBackgroundColor};
  border: 0; 
`;

const StyledSelectedBonusWrapper = styled.div`
  display: flex;
  flex: 1;
  width: calc(100% - 52px);
  grid-gap: 8px;
  padding: 8px 12px 8px 8px;
  border: 1 dashed ${({theme}) => `${theme.textContentColor}30`};
  border-radius: ${(props) => getBorderRadius(props, 'input')}; // Handles border-radius for all corners
`;

const StyledSelectedBonusIconWrapper = styled.div`  
  width: 40px;
  height: 40px;
  ${flexContainer};
`;

const StyledSelectedBonusInfoWrapper = styled.div`
  ${flexColumnContainer()};
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  grid-gap: 4px;
  max-width: 100%;
  overflow: hidden;
`;

export {
  StyledChooseBonusText,
  StyledTextButton,
  StyledSelectedBonusWrapper,
  StyledSelectedBonusIconWrapper,
  StyledSelectedBonusInfoWrapper
};
