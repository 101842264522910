import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// styled
import {
  StyledSelectAccountStatusLabel,
  StyledSelectOption,
  StyledSelectOptionsWrapper,
  StyledSelectOptionTemplateTwo,
  StyledSelectText
} from '../../../styledComponents/select/select.styled';
import {StyledHistoryUploadedWrapper, StyledUploadedList} from '../../../styledComponents/list/list.styled';
import {StyledPaymentItemLabel, StyledPaymentItemValue} from '../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {ACCOUNT_VERIFICATION_STATUSES_COLORS} from '../../../constants/styled';

function VerificationHistoryContainer({isMobile, historyData, forTempTwo}) {
  const {t} = useTranslation();

  const SelectOption = forTempTwo ? StyledSelectOptionTemplateTwo : StyledSelectOption;

  return (
    <StyledSelectOptionsWrapper>
      {historyData.map(({uploadedDate, merchantUserAccountNumberStatus}) => (
        <StyledUploadedList key={uploadedDate}>
          <StyledPaymentItemLabel>
            {t(('uploadedDate')?.toLowerCase())}
            :
            <StyledPaymentItemValue>{uploadedDate}</StyledPaymentItemValue>
          </StyledPaymentItemLabel>
          <StyledHistoryUploadedWrapper className={isMobile ? 'withoutPaddingLeft' : ''}>
            {merchantUserAccountNumberStatus?.map(({accountNumber, accountStatus}) => (
              <SelectOption hasNoCardIcon key={accountNumber}>
                <StyledSelectText>{accountNumber}</StyledSelectText>
                <StyledSelectAccountStatusLabel
                  backgroundColor={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.backgroundColor}
                  color={ACCOUNT_VERIFICATION_STATUSES_COLORS[accountStatus]?.color}
                >
                  {t((accountStatus)?.toLowerCase())}
                </StyledSelectAccountStatusLabel>
              </SelectOption>
            ))}
          </StyledHistoryUploadedWrapper>
        </StyledUploadedList>
      ))}
    </StyledSelectOptionsWrapper>
  );
}

VerificationHistoryContainer.propTypes = {
  isMobile: PropTypes.bool,
  forTempTwo: PropTypes.bool,
  historyData: PropTypes.array.isRequired
};

VerificationHistoryContainer.defaultProps = {
  isMobile: false,
  forTempTwo: false
};

export default VerificationHistoryContainer;
